<template>
    <InnerSideNavRoot>
        <template #title>Integrations</template>
        <InnerSideNavItem
            label="Title Production Software"
            url="/user/settings/integrations#vendor"
            :active="isRouteActive('#vendor')"
        />
        <InnerSideNavItem
            label="Single sign-on (SSO)"
            url="/user/settings/integrations#sso"
            :active="isRouteActive('#sso')"
        />
    </InnerSideNavRoot>
</template>

<script lang="ts" setup>
import InnerSideNavItem from './InnerSideNavItem.vue';
import InnerSideNavRoot from './InnerSideNavRoot.vue';
import { useSettingsInnerSideNavHelper } from './settings-inner-side-nav.helper';

const { isRouteActive } = useSettingsInnerSideNavHelper({ defaultHash: '#vendor' });
</script>
