<template>
    <div class="col-lg-6 offset-lg-3 legacy-md:p-8 legacy-md:shadow-xl rounded-md mx-auto mb-5 max-w-[497px]">
        <div class="mb-10">
            <a
                href="#"
                @click="backButtonClicked"
                class="text-sm text-neutral-500 focus:text-primary-600 hover:text-primary-600 btn-link"
                tabindex="1"
                ><font-awesome-icon
                    :icon="['fas', 'angle-left']"
                    class="mr-1"
                />Back</a
            >
        </div>

        <div class="flex mt-5 mb-3">
            <h3 class="text-left font-semibold text-xl font-inter">Multi-factor security</h3>
        </div>
        <p class="text-base mb-10">
            Your account requires multi-factor authentication. Your code will be sent to {{ phoneNumber }}.
        </p>

        <div class="flex mb-5">
            <div class="grow">
                <div class="mb-3">
                    <label class="font-inter font-normal text-sm">How would you like to receive the code?</label>
                    <div class="grid grid-cols-4 gap-6">
                        <div class="col-start-1 col-end-3">
                            <button
                                class="btn btn-brand font-inter font-medium text-center text-base rounded w-full max-w-full basis-1/2"
                                tabindex="2"
                                @click="
                                    e => {
                                        e.preventDefault();
                                        currentUserService.requestMFACode(props.getCodeRoute, 'sms');
                                    }
                                "
                                :disabled="!smsAvailable"
                            >
                                <font-awesome-icon
                                    :icon="['far', 'message-dots']"
                                    class="mr-1"
                                />
                                Text Me
                            </button>
                        </div>
                        <div class="col-start-3 col-end-5">
                            <button
                                class="btn btn-brand font-inter font-medium text-center text-base rounded w-full max-w-full basis-1/2"
                                tabindex="3"
                                @click="
                                    e => {
                                        e.preventDefault();
                                        currentUserService.requestMFACode(props.getCodeRoute, 'call');
                                    }
                                "
                            >
                                <font-awesome-icon
                                    :icon="['far', 'phone']"
                                    class="mr-1"
                                />
                                Call Me
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div
                class="col"
                style="text-align: center"
            >
                <p>
                    Need help? Please contact
                    <a
                        :href="'mailto:' + supportEmail"
                        class="btn-link"
                        >{{ supportEmail }}</a
                    >
                </p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useCurrentUserService } from '@/services/current-user.service';
import { ref } from 'vue';

const props = defineProps<{
    getCodeRoute: string;
    smsAvailable: string;
    supportEmail: string;
    phoneNumber: string;
}>();

const logoutForm = ref<HTMLFormElement>();
const currentUserService = useCurrentUserService();

function backButtonClicked() {
    logoutForm.value?.submit();
    currentUserService.logout();
}
</script>
