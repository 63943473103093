<template>
    <VerificationContent :indentation-level="0">
        <VerificationGrid
            :indentation-level="0"
            :grid-cols="4"
            class="text-sm"
        >
            <CheckTextualResult
                label="Institution:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: bankingInformation.institution || 'Unknown',
                    })
                "
            />
            <CheckTextualResult
                label="Bank Address:"
                :value="makeCheckResult({ state: CheckResultState.NotApplicable, text: '' })"
            >
                <span class="font-semibold break-words">
                    {{ bankingInformation.contact_information?.address }}
                    <br/>
                    {{ bankingInformation.contact_information?.city }} {{ bankingInformation.contact_information?.state }} {{ bankingInformation.contact_information?.postal_code }}
                </span>
            </CheckTextualResult>
            <CheckTextualResult
                v-if="bankingInformation.subtype"
                label="Subtype:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: bankingInformation.subtype || 'Unknown',
                    })
                "
            />
            <CheckTextualResult
                label="Source:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: sourceInformation === SourceKey.Automatic ? SourceLabel.Automatic : SourceLabel.Manual,
                    })
                "
            />
            <hr class="col-span-full w-full h-1 border-b-gray-300" />
            <div class="text-xs">
                <div>
                    Account #: <strong>{{ bankingInformation.account_number?.value }}</strong>
                </div>
            </div>
            <div class="text-xs">
                <div>
                    Wire routing #: <strong>{{ bankingInformation.wire_routing_number?.value }}</strong>
                </div>
            </div>  
        </VerificationGrid>
        <VerificationGrid
            v-if="additionalInformation"
            :indentation-level="0"
            :grid-cols="4"
            class="text-xs"
        >
            <hr class="col-span-full w-full h-1 border-b-gray-300" />
            <div>
                Notes: 
                <p>{{ additionalInformation }}</p>
            </div>
        </VerificationGrid>
    </VerificationContent>
</template>

<script lang="ts" setup>
import type { BankAccountReportResult, Source } from '@/api/interfaces/bank-account-verification.api.d';
import { CheckResultState, SourceKey, SourceLabel } from '@/api/interfaces/identity-verification-report.api';
import { makeCheckResult } from '@/components/Components/BankAccountVerification/report-check-result-mapping';
import { useBankAccountVerificationService } from '@/services/bank-account-verification.service';
import CheckTextualResult from '../../IdentityVerificationReport/common/CheckTextualResult.vue';
import VerificationContent from '../../IdentityVerificationReport/common/VerificationContent.vue';
import VerificationGrid from '../../IdentityVerificationReport/common/VerificationGrid.vue';

defineProps<{ 
    bankingInformation: BankAccountReportResult['data']['banking_information'], 
    sourceInformation: Source,
    additionalInformation: string
}>();

const bavService = useBankAccountVerificationService();
</script>
