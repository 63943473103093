<template>
    <teleport
        :disabled="!appendToBody"
        to="body"
    >
        <transition
            enter-active-class="transition ease-in-out duration-200"
            enter-from-class="opacity-0 translate-y-4"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in-out duration-200"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-4"
        >
            <div
                v-if="showModal"
                :class="{
                    ...modalPositionClasses,
                    'h-full bg-white': fullscreen,
                    'h-full legacy-md:bg-transparent bg-white': fullscreenOnMobile,
                    [modalClass]: true,
                }"
                class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 w-full legacy-md:inset-0 h-modal legacy-md:h-full justify-center items-center flex"
                role="dialog"
                :style="{ zIndex: zIndex }"
                tabindex="-1"
            >
                <div
                    v-click-outside="clickOutsideHandler"
                    :class="{ ['legacy-md:h-auto ' + width]: !fullscreen }"
                    class="relative w-full h-full"
                >
                    <!-- Modal content -->
                    <div
                        :class="{
                            'shadow bg-white rounded-lg': !fullscreen,
                            'shadow-none legacy-md:shadow bg-white rounded-none legacy-md:rounded-lg': fullscreenOnMobile,
                            [wrapperClass]: true,
                        }"
                        class="relative"
                    >
                        <!-- Modal header -->
                        <div
                            v-if="!disableHeader"
                            :class="
                                mergeTailwindClasses(
                                    'flex justify-between p-4 rounded-t border-b gap-3 items-center',
                                    headerClass,
                                )
                            "
                        >
                            <div
                                v-if="type"
                                :class="{
                                    'bg-rose-100': type == 'danger',
                                    'bg-emerald-100': type == 'success',
                                    'bg-indigo-100': type == 'info',
                                }"
                                class="w-10 h-10 rounded-full flex items-center justify-center shrink-0"
                            >
                                <svg
                                    v-if="type == 'danger'"
                                    class="w-4 h-4 shrink-0 fill-current text-rose-500"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
                                    />
                                </svg>
                                <svg
                                    v-else-if="type == 'success'"
                                    class="w-4 h-4 shrink-0 fill-current text-emerald-500"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
                                    />
                                </svg>
                                <svg
                                    v-else-if="type == 'info'"
                                    class="w-4 h-4 shrink-0 fill-current text-indigo-500"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                                    />
                                </svg>
                            </div>

                            <h3 class="text-xl font-semibold text-gray-900">
                                <template v-if="$slots['title']">
                                    <slot name="title" />
                                </template>
                                <template v-else>
                                    {{ title }}
                                </template>
                            </h3>
                            <button
                                v-if="showClose"
                                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center shadow-none"
                                data-modal-toggle="defaultModal"
                                type="button"
                                @click="closeHandler"
                            >
                                <Close @click="userClosedHandler" />
                            </button>
                        </div>
                        <!-- Modal body -->
                        <div
                            v-if="$slots['default']"
                            :class="mergeTailwindClasses('p-6 space-y-6', bodyClass)"
                        >
                            <slot name="default" />
                        </div>
                        <!-- Modal footer -->
                        <div
                            :class="footerClass"
                            class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 w-full"
                        >
                            <slot name="footer" />
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-out duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-if="showModal"
                class="bg-gray-900 bg-opacity-50 fixed inset-0"
                :style="{ zIndex: zIndex - 10 }"
            ></div>
        </transition>
    </teleport>
</template>

<script lang="ts">
import Close from '@/components/Icons/Close.vue';
import { isFunction } from 'lodash';
import { twMerge, type ClassNameValue } from 'tailwind-merge';
import { defineComponent } from 'vue';

let closeOnEsc: (event: KeyboardEvent) => void;

const Modal = defineComponent({
    components: { Close },
    emits: ['update:visible', 'open'],
    props: {
        type: String,
        visible: Boolean,
        title: String,
        appendToBody: {
            type: Boolean,
            default: false,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        fullscreenOnMobile: {
            type: Boolean,
            default: false,
        },
        top: {
            type: [String, Number],
            default: 0,
        },
        width: {
            type: String,
            default: 'max-w-2xl',
        },
        disableHeader: {
            type: Boolean,
            default: false,
        },
        disableClose: {
            type: Boolean,
            default: false,
        },
        escToClose: {
            type: Boolean,
            default: true,
        },
        showClose: {
            type: Boolean,
            default: true,
        },
        lockScroll: {
            type: Boolean,
            default: false,
        },
        modalClass: {
            type: String,
            default: 'p-4',
        },
        wrapperClass: {
            type: String,
            default: '',
        },
        headerClass: {
            type: String,
        },
        bodyClass: {
            type: String,
        },
        footerClass: {
            type: String,
        },
        zIndex: {
            type: Number,
            default: 50,
        },
        disableCloseWhenClickOutside: {
            type: Boolean,
            default: false,
        },
        beforeClose: Function,
        eventToCloseFunction: Function,
    },
    mounted() {
        this.triggerModal();
        const componentInstance = this
        closeOnEsc = (function (this: typeof componentInstance, event: KeyboardEvent) {
            if (this.visible && event.key === 'Escape' && this.escToClose) {
                if (this.eventToCloseFunction) {
                    this.eventToCloseFunction();
                }
                this.closeHandler();
            }
        }).bind(this);

        document.addEventListener('keydown', closeOnEsc);
    },
    unmounted() {
        document.removeEventListener('keydown', closeOnEsc);
    },
    data() {
        return {
            showModal: this.visible,
        };
    },
    methods: {
        triggerModal(visible = this.showModal) {
            this.showModal = visible ? this.openHandler() : this.closeHandler();
        },
        async openHandler() {
            this.$emit('open', this.closeHandler);
            this.showModal = true;
            this.lockScroll && document.body.classList.add('overflow-y-hidden');
        },
        clickOutsideHandler() {
            if (this.eventToCloseFunction) {
                this.eventToCloseFunction();
            }
            if (!this.disableCloseWhenClickOutside) {
                this.closeHandler();
            }
        },
        userClosedHandler() {
            if (isFunction(this.eventToCloseFunction)) {
                this.eventToCloseFunction();
            }
        },
        closeHandler() {
            if (isFunction(this.beforeClose)) {
                this.beforeClose();
            }

            this.showModal = false;
            this.$emit('update:visible', false);
            this.lockScroll && document.body.classList.remove('overflow-y-hidden');
        },
        mergeTailwindClasses(...classes: ClassNameValue[]) {
            return twMerge(...classes);
        },
    },
    computed: {
        modalPositionClasses() {
            switch (parseInt(`${this.top}`)) {
                case 0:
                    return { ['top-[0%]']: true, ['translate-y-[-0%]']: true };
                case 25:
                    return { ['top-[25%]']: true, ['translate-y-[-25%]']: true };
                case 50:
                    return { ['top-[50%]']: true, ['translate-y-[-50%]']: true };
                case 75:
                    return { ['top-[75%]']: true, ['translate-y-[-75%]']: true };
                case 100:
                    return { ['top-[100%]']: true, ['translate-y-[-100%]']: true };
            }
            return {};
        },
        bodyClassMerged() {
            return twMerge('p-6 space-y-6', this.bodyClass);
        },
    },
    watch: {
        visible(newVisible) {
            this.triggerModal(newVisible);
        },
    },
});
export default Modal;
</script>
