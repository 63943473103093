<template>
    <section>
        <div class="text-heading-md font-extrabold">Display & Headings</div>
        <div class="text-md mt-3 font-semibold">Display lg</div>
        <div class="text-display-lg flex flex-row gap-4 justify-evenly">
            <div
                class="font-extrabold"
                contenteditable="true"
            >
                Extra bold
            </div>
            <div
                class="font-semibold"
                contenteditable="true"
            >
                Semi bold
            </div>
            <div
                class="font-normal"
                contenteditable="true"
            >
                Normal
            </div>
        </div>
        <div class="text-md mt-3 font-semibold">Display md</div>
        <div class="text-display-md flex flex-row gap-4 justify-evenly">
            <div
                class="font-extrabold"
                contenteditable="true"
            >
                Extra bold
            </div>
            <div
                class="font-semibold"
                contenteditable="true"
            >
                Semi bold
            </div>
            <div
                class="font-normal"
                contenteditable="true"
            >
                Normal
            </div>
        </div>
        <div class="text-md mt-3 font-semibold">Display sm</div>
        <div class="text-display-sm flex flex-row gap-4 justify-evenly">
            <div
                class="font-extrabold"
                contenteditable="true"
            >
                Extra bold
            </div>
            <div
                class="font-semibold"
                contenteditable="true"
            >
                Semi bold
            </div>
            <div
                class="font-normal"
                contenteditable="true"
            >
                Normal
            </div>
        </div>
        <div class="text-md mt-3 font-semibold">Heading lg</div>
        <div class="text-heading-lg flex flex-row gap-4 justify-evenly">
            <div
                class="font-bold"
                contenteditable="true"
            >
                Bold
            </div>
            <div
                class="font-semibold"
                contenteditable="true"
            >
                Semi bold
            </div>
            <div
                class="font-normal"
                contenteditable="true"
            >
                Normal
            </div>
        </div>
        <div class="text-md mt-3 font-semibold">Heading md</div>
        <div class="text-heading-md flex flex-row gap-4 justify-evenly">
            <div
                class="font-bold"
                contenteditable="true"
            >
                Bold
            </div>
            <div
                class="font-semibold"
                contenteditable="true"
            >
                Semi bold
            </div>
            <div
                class="font-normal"
                contenteditable="true"
            >
                Normal
            </div>
        </div>
        <div class="text-md mt-3 font-semibold">Heading sm</div>
        <div class="text-heading-sm flex flex-row gap-4 justify-evenly">
            <div
                class="font-bold"
                contenteditable="true"
            >
                Bold
            </div>
            <div
                class="font-semibold"
                contenteditable="true"
            >
                Semi bold
            </div>
            <div
                class="font-normal"
                contenteditable="true"
            >
                Normal
            </div>
        </div>
        <div class="text-heading-md font-extrabold">Body</div>
        <div class="text-md mt-3 font-semibold">Content lg</div>
        <div class="text-content-lg flex flex-row gap-4 justify-evenly">
            <div
                class="font-semibold"
                contenteditable="true"
            >
                Semi bold
            </div>
            <div
                class="font-medium"
                contenteditable="true"
            >
                Medium
            </div>
            <div
                class="font-normal"
                contenteditable="true"
            >
                Normal
            </div>
        </div>
        <div class="text-md mt-3 font-semibold">Content md</div>
        <div class="text-content-md flex flex-row gap-4 justify-evenly">
            <div
                class="font-semibold"
                contenteditable="true"
            >
                Semi bold
            </div>
            <div
                class="font-medium"
                contenteditable="true"
            >
                Medium
            </div>
            <div
                class="font-normal"
                contenteditable="true"
            >
                Normal
            </div>
        </div>
        <div class="text-md mt-3 font-semibold">Content sm</div>
        <div class="text-content-sm flex flex-row gap-4 justify-evenly">
            <div
                class="font-semibold"
                contenteditable="true"
            >
                Semi bold
            </div>
            <div
                class="font-medium"
                contenteditable="true"
            >
                Normal
            </div>
            <div
                class="font-normal"
                contenteditable="true"
            >
                Normal
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup></script>
