<template>
    <VerificationSubsection
        title="Address"
        :indentation-level="1"
    >
        <GoogleMap
            class="w-full h-72"
            v-if="reportMapLocation.lat && reportMapLocation.lng"
            :map-center="reportMapLocation"
            :markerCoordinates="[reportMapLocation]"
        />
        <VerificationGrid :indentation-level="1">
            <CheckTextualResult
                label="Resolved Address:"
                :value="additionalInformation.geolocation.address"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_FULLY_FORMATED_ADDRESS)"
            />
            <CheckTextualResult
                label="Record Type:"
                :value="additionalInformation.geolocation.recordType"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_RECORD_TYPE)"
            />
            <CheckTextualResult
                label="County FIPS:"
                :value="additionalInformation.geolocation.countryFips"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_COUNTY_FIPS)"
            />
            <CheckTextualResult
                label="County Name:"
                :value="additionalInformation.geolocation.country"
                v-tooltip.left="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_COUNTY_NAME)"
            />
            <CheckTextualResult
                label="Property Type:"
                :value="additionalInformation.geolocation.propertyType"
                v-tooltip.left="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_PROPERTY_TYPE)"
            />
            <CheckTextualResult
                label="DPV Match:"
                :value="additionalInformation.geolocation.dpvMatch"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_DPV_MATCH)"
            />
            <CheckTextualResult
                label="DPV Vacant:"
                :value="additionalInformation.geolocation.dpvVacant"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_DPV_VACANT)"
            />
            <CheckTextualResult
                label="Active:"
                :value="additionalInformation.geolocation.active"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_ACTIVE)"
            />
        </VerificationGrid>
    </VerificationSubsection>
    <VerificationSubsection
        title="E-mail risk"
        :indentation-level="1"
        :helper-text="IDV_HELPER_ADD_INFO_EMAIL_RISK"
    >
        <VerificationGrid :indentation-level="1">
            <CheckTextualResult
                label="Email:"
                :value="additionalInformation.emailRisk.email"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_EMAIL_RISK)"
            />
            <CheckTextualResult
                label="Age:"
                :value="additionalInformation.emailRisk.estimatedAge"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_EMAIL_AGE)"
            />
            <CheckTextualResult
                label="Reputation:"
                :value="additionalInformation.emailRisk.reputation"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_EMAIL_REPUTABILITY)"
            />
            <CheckTextualResult
                label="Suspicious:"
                :value="additionalInformation.emailRisk.suspicious"
                v-tooltip.left="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_EMAIL_SUSPICIOUS)"
            />
            <CheckTextualResult
                label="Disposable:"
                :value="additionalInformation.emailRisk.disposable"
                v-tooltip.left="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_EMAIL_DISPOSABLE)"
            />
            <CheckTextualResult
                label="Spam:"
                :value="additionalInformation.emailRisk.spam"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_EMAIL_SPAMMY)"
            />
            <CheckTextualResult
                label="Deliverable:"
                :value="additionalInformation.emailRisk.deliverable"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_EMAIL_DELIVERABLE)"
            />
            <CheckTextualResult
                label="Domain Created:"
                :value="additionalInformation.emailRisk.domainAge"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_EMAIL_DOMAIN_AGE)"
            />
        </VerificationGrid>
    </VerificationSubsection>
    <VerificationSubsection
        title="Phone risk"
        :indentation-level="1"
        :helper-text="IDV_HELPER_ADD_INFO_PHONE_RISK"
    >
        <VerificationGrid :indentation-level="1">
            <CheckTextualResult
                label="Phone Number:"
                :value="additionalInformation.phoneRisk.phoneNumber"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_PHONE_CLIENT_PHONE_NUMBER)"
            />
            <CheckTextualResult
                label="Phone Type:"
                :value="additionalInformation.phoneRisk.phoneType"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_PHONE_TYPE)"
            />
            <CheckTextualResult
                label="Account Holder:"
                :value="additionalInformation.phoneRisk.accountHolder"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_PHONE_ACCOUNT_HOLDER)"
            />
            <CheckTextualResult
                label="Phone Carrier:"
                :value="additionalInformation.phoneRisk.carrier"
                v-tooltip.left="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_PHONE_PHONE_CARRIER)"
            />
            <CheckTextualResult
                label="Risk Level:"
                :value="additionalInformation.phoneRisk.riskLevel"
                v-tooltip.left="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_PHONE_RISK_LEVEL)"
            />
            <CheckTextualResult
                label="Risk Recommendation:"
                :value="additionalInformation.phoneRisk.riskRecommendation"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_PHONE_RISK_RECOMMENDATION)"
            />
            <CheckTextualResult
                label="Address Compariso:"
                :value="additionalInformation.phoneRisk.addressComparison"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_PHONE_ADDRESS_COMPARISON)"
            />
            <CheckTextualResult
                label="Birthdate Comparison:"
                :value="additionalInformation.phoneRisk.birthDateComparison"
                v-tooltip="GEN_IDV_STYLED_TOOLTIP(IDV_HELPER_ADD_INFO_PHONE_BIRTHDATE_COMPARISON)"
            />
        </VerificationGrid>
    </VerificationSubsection>
</template>

<script lang="ts" setup>
import { AdditionalInformationAnalysis } from '@/api/interfaces/identity-verification-report.api';
import { computed } from 'vue';
import GoogleMap from '../../Map/GoogleMap.vue';
import CheckTextualResult from '../common/CheckTextualResult.vue';
import VerificationGrid from '../common/VerificationGrid.vue';
import VerificationSubsection from '../common/VerificationSubsection.vue';
import { GEN_IDV_STYLED_TOOLTIP } from '../common/idv-helper-tooltip.pt';
import {
    IDV_HELPER_ADD_INFO_ACTIVE,
    IDV_HELPER_ADD_INFO_COUNTY_FIPS,
    IDV_HELPER_ADD_INFO_COUNTY_NAME,
    IDV_HELPER_ADD_INFO_DPV_MATCH,
    IDV_HELPER_ADD_INFO_DPV_VACANT,
    IDV_HELPER_ADD_INFO_EMAIL_AGE,
    IDV_HELPER_ADD_INFO_EMAIL_DELIVERABLE,
    IDV_HELPER_ADD_INFO_EMAIL_DISPOSABLE,
    IDV_HELPER_ADD_INFO_EMAIL_DOMAIN_AGE,
    IDV_HELPER_ADD_INFO_EMAIL_REPUTABILITY,
    IDV_HELPER_ADD_INFO_EMAIL_RISK,
    IDV_HELPER_ADD_INFO_EMAIL_SPAMMY,
    IDV_HELPER_ADD_INFO_EMAIL_SUSPICIOUS,
    IDV_HELPER_ADD_INFO_FULLY_FORMATED_ADDRESS,
    IDV_HELPER_ADD_INFO_PHONE_ACCOUNT_HOLDER,
    IDV_HELPER_ADD_INFO_PHONE_ADDRESS_COMPARISON,
    IDV_HELPER_ADD_INFO_PHONE_BIRTHDATE_COMPARISON,
    IDV_HELPER_ADD_INFO_PHONE_CLIENT_PHONE_NUMBER,
    IDV_HELPER_ADD_INFO_PHONE_PHONE_CARRIER,
    IDV_HELPER_ADD_INFO_PHONE_RISK,
    IDV_HELPER_ADD_INFO_PHONE_RISK_LEVEL,
    IDV_HELPER_ADD_INFO_PHONE_RISK_RECOMMENDATION,
    IDV_HELPER_ADD_INFO_PHONE_TYPE,
    IDV_HELPER_ADD_INFO_PROPERTY_TYPE,
    IDV_HELPER_ADD_INFO_RECORD_TYPE,
} from '../idv-static-helper-text';

const props = defineProps<{
    additionalInformation: AdditionalInformationAnalysis;
}>();

const reportMapLocation = computed(() => ({
    lat: props.additionalInformation.geolocation.lat,
    lng: props.additionalInformation.geolocation.lng,
}));
</script>
