<template>
    <InnerSideNavRoot>
        <template #title>Admin</template>
        <InnerSideNavItem
            label="Account settings"
            url="/user/settings#account"
            :active="isRouteActive('#account')"
        />
        <InnerSideNavItem
            label="Branch settings"
            url="/user/settings#branch"
            :active="isRouteActive('#branch')"
        />
        <InnerSideNavItem
            label="Wire instructions"
            url="/user/settings#wire"
            :active="isRouteActive('#wire')"
        />
        <InnerSideNavItem
            label="Notifications"
            url="/user/settings#notifications"
            :active="isRouteActive('#notifications')"
        />
    </InnerSideNavRoot>
</template>

<script lang="ts" setup>
import InnerSideNavItem from './InnerSideNavItem.vue';
import InnerSideNavRoot from './InnerSideNavRoot.vue';
import { useSettingsInnerSideNavHelper } from './settings-inner-side-nav.helper';

const { isRouteActive } = useSettingsInnerSideNavHelper({ defaultHash: '#account' });
</script>
