<template>
    <TabNavContainer title="Settings">
        <template #navigation>
            <TabMenu
                :model="items"
                :activeIndex="activeIndex"
            >
            </TabMenu>
        </template>
    </TabNavContainer>
</template>

<script lang="ts" setup>
import type { SessionUserRole } from '@/interfaces/SessionUser';
import type { MenuItem } from 'primevue/menuitem';
import TabMenu from 'primevue/tabmenu';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import TabNavContainer from './TabNavContainer.vue';

const route = useRoute();

type TabMenuItem = MenuItem & {
    hash?: string;
};

const props = defineProps<{ userRole: SessionUserRole }>();

function buildItem(label: string, path: string, hash: string): TabMenuItem {
    return {
        label,
        path,
        hash,
        url: `${path}#${hash}`,
    };
}

const hasUserMenu = computed(() => props.userRole === 'Admin' || props.userRole === 'Employee');

const items = ref<TabMenuItem[]>(
    [
        buildItem('Admin', '/user/settings', 'account'),
        { label: '|', separator: true, disabled: true },
        buildItem('Integrations', '/user/settings/integrations', 'vendor'),
    ].filter(Boolean),
);

if (hasUserMenu.value) {
    items.value.splice(2, 0, buildItem('Users', '/user/manage', 'all-users'), { label: '|', separator: true, disabled: true });
}

const activeIndex = ref(items.value.findIndex(item => item.path === route.path));
</script>
