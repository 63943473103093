<template>
    <section :class="`fixed-height-${fixedHeight}`">
        <fieldset>
            <legend>Toggles</legend>
            <div>
                <label>
                    <input type="radio" name="fixed-height" value="natural" v-model="fixedHeight">
                    <span>Natural height</span>
                </label>
                <label>
                    <input type="radio" name="fixed-height" value="container" v-model="fixedHeight">
                    <span>Fixed container height</span>
                </label>
                <label>
                    <input type="radio" name="fixed-height" value="item" v-model="fixedHeight">
                    <span>Fixed item height</span>
                </label>
            </div>
            <label>
                <input type="checkbox" name="break-words" v-model="hasWordBreak">
                <span>Avoid overflow</span>
            </label>
        </fieldset>

        <h4>Columns at <kbd>{{screenWidth}}px</kbd> (whole screen), <kbd>{{ gridWidth }}px</kbd> (content)</h4>
        <div class="ds-grid" :class="{ 'break-all': hasWordBreak }" ref="gridRef">
            <div>1</div>
            <div>2</div>
            <div>3</div>
            <div>4</div>
            <div class="max-md:hidden">5</div>
            <div class="max-md:hidden">6</div>
            <div class="max-md:hidden">7</div>
            <div class="max-md:hidden">8</div>
            <div class="max-lg:hidden">9</div>
            <div class="max-lg:hidden">10</div>
            <div class="max-lg:hidden">11</div>
            <div class="max-lg:hidden">12</div>
        </div>

        <h4>Adaptive spanning</h4>
        <div class="ds-grid" :class="{ 'break-all': hasWordBreak }">
            <div class="ds-grid-span-adaptive">Full ↔ Half ↔ Quarter</div>
            <div class="ds-grid-span-adaptive">Full ↔ Half ↔ Quarter</div>
            <div class="ds-grid-span-adaptive">Full ↔ Half ↔ Quarter</div>
            <div class="ds-grid-span-adaptive">Full ↔ Half ↔ Quarter</div>
            <div class="ds-grid-span-full">Full</div>
            <div class="ds-grid-span-half">Half</div>
            <div class="ds-grid-span-half">Half</div>
            <div class="ds-grid-span-quarter">Quarter</div>
            <div class="ds-grid-span-quarter">Quarter</div>
            <div class="ds-grid-span-quarter">Quarter</div>
            <div class="ds-grid-span-quarter">Quarter</div>
            <div>Unit 1</div>
            <div>Unit 2</div>
            <div>Unit 3</div>
            <div>Unit 4</div>
            <div>Unit 5</div>
            <div>Unit 6</div>
            <div>Unit 7</div>
            <div>Unit 8</div>
            <div>Unit 9</div>
            <div>Unit 10</div>
            <div>Unit 11</div>
            <div>Unit 12</div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { throttle } from 'lodash';
import { nextTick, onMounted, ref } from 'vue';

const gridRef = ref<HTMLElement>();
const fixedHeight = ref<'natural' | 'container' | 'item'>('natural');
const hasWordBreak = ref(false);
const gridWidth = ref(0);
const screenWidth = ref(0);
const resizeHandler = throttle(() => {
    gridWidth.value = gridRef.value?.clientWidth ?? 0;
    screenWidth.value = window.innerWidth;
}, 100);

onMounted(() => {
    nextTick(resizeHandler);
});

window.addEventListener('resize', resizeHandler, { passive: true });
</script>

<style scoped lang="scss">
* {
    box-sizing: border-box;
}

h2, h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

h2:first-child {
    margin-top: 0;
}

h4 {
    margin-bottom: 0.5rem;
}

fieldset {
    border: 1px solid lightgrey;
    border-radius: 0.25rem;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem 2rem;
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
}

label {
    display: flex;
    gap: 1ch;
    align-items: center;
    width: max-content;
}

.ds-grid {
    border-radius: 0.25em;
    grid-auto-rows: max-content;
    margin-bottom: 2rem;
    outline: 2px dashed skyblue;

    > div {
        background-color: #f333;
        padding: 0.5em;
    }
}

.fixed-height-container {
    .ds-grid {
        grid-auto-rows: initial;
        height: 200px;
        overflow: hidden;
    }
}

.fixed-height-item {
    .ds-grid {
        min-height: initial;

        > div {
            height: 120px;
        }
    }
}

kbd {
    background-color: #f3f3f3;
    border-radius: 0.25em;
    padding: 0 0.5ch;
}
</style>
