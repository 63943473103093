<script lang="ts" setup>
import dayjs from 'dayjs';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import { useField, useForm } from 'vee-validate';
import { onMounted } from 'vue';

import { EditStartDateSubmitPayload } from '@/employee-portal/pages/accounts/forms/form-payloads';
import { FlatWireDoc } from '@/interfaces/payment-settings/escrow-account';

const props = defineProps<{ flatWireDoc?: FlatWireDoc; suggestedStartDate?: Date }>();
const emits = defineEmits<{
    (event: 'submit', payload: EditStartDateSubmitPayload): void;
    (event: 'cancel'): void;
}>();

const { handleSubmit, validate } = useForm();
const { value: startDate, errorMessage: startDateErrorMessage } = useField<Date>('startDate', value =>
    value ? true : 'Select a valid date',
);

function setInitialStartDate() {
    if (!props.suggestedStartDate) {
        const parsedDate = props.flatWireDoc?.payment_start_date ? dayjs(props.flatWireDoc.payment_start_date) : dayjs();
        startDate.value = parsedDate.toDate();
        return;
    }
    startDate.value = props.suggestedStartDate;
}

onMounted(() => {
    setInitialStartDate();
});

const onSubmit = handleSubmit(values => {
    emits('submit', { flatWireDoc: props.flatWireDoc, values: { startDate: values.startDate } });
});
const onCancel = () => {
    emits('cancel');
};
</script>

<template>
    <form @submit="onSubmit">
        <h3 class="text-center font-semibold">Enter the Start Date</h3>
        <div class="text-sm font-normal text-gray-400 mb-9 text-center">When do you want payments to start?</div>
        <div class="flex flex-col gap-8 font-medium">
            <div class="flex flex-col gap-1">
                <label class="text-sm font-normal">Start Date:</label>
                <Calendar
                    v-model="startDate"
                    :input-style="{ width: '100%' }"
                    date-format="mm/dd/yy"
                    :pt="{ day: 'p-0 text-sm' }"
                />
                <span
                    class="text-red-500 text-xs"
                    v-show="startDateErrorMessage"
                    >{{ startDateErrorMessage }}</span>
            </div>
            <div class="flex flex-col gap-1">
                <Button
                    label="Enable Payments"
                    type="submit"
                />
                <Button
                    outlined
                    label="Cancel"
                    type="button"
                    @click="onCancel"
                />
            </div>
        </div>
    </form>
</template>
