<template>
    <div class="font-inter">
        <div class="w-full flex space-between px-8 mt-8">
            <div class="flex-1">
                <h2 class="font-bold">Identity Verification Details</h2>
                <div
                    class="text-xl"
                    v-if="mapping?.file?.ref_number"
                >
                    File: {{ mapping.file.ref_number }}
                </div>
            </div>
            <div class="mt-4 flex-initial text-right vertical-center">
                <h1 :class="statusClass">{{ statusString }}</h1>
            </div>
        </div>
        <Divider />
        <div
            class="w-full flex flex-col items-start gap-4 px-8"
            v-if="reportState === 'loaded' && identityVerificationResult && mapping"
        >
            <HeaderReportBanner />
            <OverallResult
                :result="identityVerificationResult.pdfMapping.overallResult"
                :selfie-enabled="identityVerificationResult.pdfMapping.selfieVerification.selfieAnalysisEnabled"
            />
            <VerificationSection
                title="Know Your Customer (KYC)"
                :sectionCheckResult="mapping.overallResult.knownYourCustomer"
                :helperText="IDV_HELPER_KYC_CHECK"
                omit-error-list
            >
                <KYCReport
                    :known-your-customer="mapping.knownYourCustomer"
                    :country
                />
            </VerificationSection>
            <VerificationSection
                title="Government ID Analysis"
                :sectionCheckResult="mapping.overallResult.govIdAnalysis"
                :helperText="IDV_HELPER_GOVERNMENT_ID_ANALYSIS"
                omit-error-list
            >
                <GovernmentIdReport
                    class="mt-4"
                    :gov-id-analysis="mapping.govIdAnalysis"
                    :country
                />
            </VerificationSection>
            <VerificationSection
                v-if="mapping.selfieVerification.selfieAnalysisEnabled"
                title="Selfie Verification"
                :sectionCheckResult="mapping.overallResult.selfieVerification"
                :helperText="IDV_HELPER_SELFIE_VERIFICATION"
                omit-error-list
            >
                <SelfieReport
                    :selfie-analysis="mapping.selfieVerification"
                    :selfie-overall-result="mapping.overallResult.selfieVerification"
                />
            </VerificationSection>
            <VerificationSection
                title="IP Address information"
                :sectionCheckResult="STATIC_REVIEW_RESULTS"
                :helperText="IDV_HELPER_IP_ADDRESS_INFORMATION"
            >
                <IPAddressReport :ip-analysis="mapping.ipAddressResult" />
            </VerificationSection>
            <VerificationSection
                title="Additional information"
                :sectionCheckResult="STATIC_REVIEW_RESULTS"
                :helperText="`Additional Information allows you to enrich your understanding of the subject using key
             identifiers like email addresses, phone numbers and online presence pulled from authoritative 
             data sources.`"
            >
                <AdditionalInformationReport :additional-information="mapping.additionalInformation" />
            </VerificationSection>
        </div>
        <div
            class="flex flex-col gap-2 w-full"
            v-if="reportState === 'loaded'"
        >
            <Divider />
            <div class="flex flex-row items-end justify-end gap-1 text-sm px-8">
                <a
                    :href="`/user/id-verification/${props.id}/download`"
                    target="_blank"
                >
                    <Button class="w-fit bg-[#ddd] m-1 px-7 py-[15px] text-[#444] shadow-md border-neutral-100" unstyled>
                        <font-awesome-icon icon="fas fa-file-pdf" />
                    </Button>
                </a>

                <Button
                    class="w-fit bg-[#ddd] m-1 px-7 py-[15px] font-normal text-[#444] shadow-md border-neutral-100"
                    @click="$emit('close')"
                    unstyled
                >
                    Close
                </Button>
            </div>
        </div>
        <div v-if="reportState === 'loading'">
            <ModalLoader>
                <template #loadingText>
                    <div class="text-base text-neutral-500">Loading Identity verification request</div>
                </template> 
            </ModalLoader>
        </div>
        <div v-if="reportState === 'failure'">
            <IDVReportLoadingFailure
                @try-reload="loadReport(props.id)"
                @close="$emit('close')"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { CheckResult, IdentityVerificationResult } from '@/api/interfaces/identity-verification-report.api';
import { CheckResultState } from '@/api/interfaces/identity-verification-report.api';
import { useIdentityVerificationReportService } from '@/services/identity-verification-report.service';

import ModalLoader from '@/components/Components/ModalLoader.vue';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import { computed, onMounted, ref, watch } from 'vue';
import HeaderReportBanner from './common/HeaderReportBanner.vue';
import VerificationSection from './common/VerificationSection.vue';
import {
    IDV_HELPER_GOVERNMENT_ID_ANALYSIS,
    IDV_HELPER_IP_ADDRESS_INFORMATION,
    IDV_HELPER_KYC_CHECK,
    IDV_HELPER_SELFIE_VERIFICATION,
} from './idv-static-helper-text';
import IDVReportLoadingFailure from './IDVReportLoadingFailure.vue';

import AdditionalInformationReport from './reports/AdditionalInformationReport.vue';
import GovernmentIdReport from './reports/GovernmentIdReport.vue';
import IPAddressReport from './reports/IPAddressReport.vue';
import KYCReport from './reports/KYCReport.vue';
import OverallResult from './reports/OverallResult.vue';
import SelfieReport from './reports/SelfieReport.vue';

const props = defineProps<{
    id: IdentityVerificationResult['id'];
}>();

defineEmits(['close']);

const STATIC_REVIEW_RESULTS: CheckResult = {
    state: CheckResultState.NotApplicable,
    stateText: 'Review Results',
    text: 'Review Results',
    infoList: [],
};

const identityVerificationResult = ref<IdentityVerificationResult | undefined>();

const reportState = ref<'loading' | 'failure' | 'loaded'>('loading');

const idvReportService = useIdentityVerificationReportService();

async function loadReport(id: IdentityVerificationResult['id']) {
    reportState.value = 'loading';
    try {
        identityVerificationResult.value = (await idvReportService.loadIdvReport(id)) ?? undefined;
    } catch (error) {
        reportState.value = 'failure';
    } finally {
        reportState.value = reportState.value === 'loading' ? 'loaded' : reportState.value;
    }
}

onMounted(async () => {
    loadReport(props.id);
});

watch(
    () => props.id,
    id => {
        loadReport(id);
    },
);

const mutatedReport = computed(() =>
    identityVerificationResult?.value ? idvReportService.mutateReport(identityVerificationResult.value) : null,
);

const statusString = computed(() => mutatedReport.value?.status);

const statusClass = computed(() => {
    const rawStatus = identityVerificationResult.value?.raw_status;
    if (rawStatus === 'success' || rawStatus === 'amended') {
        return 'text-successful-600';
    } else if (rawStatus === 'overridden') {
        return 'text-neutral-600';
    } else {
        return 'text-destructive-500';
    }
});
const mapping = computed(() => mutatedReport.value?.pdfMapping);
const country = computed(() => mutatedReport.value?.reports?.selected_country_code || 'US');
</script>
