import { MatchResultState, SourceType, type BAVReportMatchCheckResult } from '@/api/interfaces/bank-account-verification.api.d';
import type { CheckResult } from '@/api/interfaces/identity-verification-report.api';
import { CheckResultState } from '@/api/interfaces/identity-verification-report.api';

export function makeCheckResult(overrides: Partial<CheckResult> = {}): CheckResult {
    return {
        state: CheckResultState.NotApplicable,
        text: '',
        stateText: '',
        infoList: [],
        ...overrides,
    };
}

export function mapBavMatchToCheckResult(check: BAVReportMatchCheckResult): CheckResult {
    if (!check.value) {
        return {
            state: CheckResultState.NotApplicable,
            text: 'Unknown',
            stateText: 'Unknown',
            infoList: [],
        };
    }
    const matchCorrespondence = {
        [MatchResultState.match]: CheckResultState.Passed,
        [MatchResultState.closeMatch]: CheckResultState.CautiousFailed,
        [MatchResultState.noMatch]: CheckResultState.Failed,
        [MatchResultState.unknown]: CheckResultState.Unverified,
    } as const;

    const matchText = {
        [MatchResultState.match]: 'Match',
        [MatchResultState.closeMatch]: 'Close match',
        [MatchResultState.noMatch]: 'Failed',
        [MatchResultState.unknown]: 'Unverified',
    } as const;

    return {
        state: matchCorrespondence[check.match ?? MatchResultState.unknown],
        text: check.value ?? 'Unknown',
        suppressMatch: check.suppressMatch ?? false,
        stateText: matchText[check.match ?? MatchResultState.unknown],
        infoList: [],
    };
}

export function mapSourceToText(type?: SourceType) {
    if (!type) {
        return '';
    }
    const sourceCorrespondence = {
        [SourceType.manual]: 'User submitted',
        [SourceType.idv]: 'Identity Verification',
        [SourceType.title]: 'Title Company',
    };
    return sourceCorrespondence[type] ?? type;
}
