type BAVStatuses = 'received' | 'sent' | 'completed';
export interface BAVReturnData {
    file_information: FileInformation;
    banking_information: BankingInformation;
    account_status: AccountStatus;
    account_details: AccountDetails;
    additional_information: string;
}

export interface BAVLoadingData {
    message: string;
}

export type BAVReportData = BAVReturnData | BAVLoadingData;
export interface BankAccountReportResult {
    uuid?: string;
    status: BAVStatuses;
    data: BAVReportData;
}

export interface BAVReturnDataWithStatusCode
    extends BankAccountReportResult {
    statusCode: number | null;
}

export interface BusinessInformation {
    business_name: string;
    ein: string;
}
export interface AccountDetails {
    business_name?: string;
    bank_mailing_address?: string;
    client_address: string;
    zip_code: BAVReportMatchCheckResult;
    account_holders: BavReportAccountHolder[];
    business_information?: BusinessInformation;
}

export interface BavReportAccountHolder {
    first_name: BAVReportMatchCheckResult;
    last_name: BAVReportMatchCheckResult;
    ssn: BAVReportMatchCheckResult;
    business_or_full_name: { match: MatchResultState };
}

export interface BavFormAccountHolder {
    first_name: string;
    first_name_source: Source;
    last_name: string;
    last_name_source: Source;
    ssn?: string;
}

export enum AccountHolderCheckSource {
    idv = 'idv',
}

export enum SourceType {
    manual = 'manual',
    idv = 'idv',
    title = 'title',
    client = 'client',
}

export enum MatchResultState {
    match = 'Y',
    noMatch = 'N',
    closeMatch = 'C',
    unknown = 'U', // TODO double check this value against BE implementation. "U" was present in the json mock but could be absent from real BE response
}

export interface BAVReportMatchCheckResult {
    value: string;
    source?: SourceType;
    match: MatchResultState | null;
    suppressMatch?: boolean;
}

export enum AccountStatusStanding {
    valid = 'valid',
    unverified = 'unverified',
}

export interface AccountStatus {
    standing: AccountStatusStanding;
    open_date: string;
    last_update?: string;
}

export interface BankingInformation {
    institution: string;
    bank_address: string;
    contact_information: {
        address: string;
        phone: string;
        city: string;
        postal_code: string;
        state: string;
        country: string;
    }
    subtype: string | null;
    account_number: BankAccountNumber;
    wire_routing_number: BankAccountNumber;
}

export enum BankAccountNumberSource {
    manual = 'manual',
    automatic = 'automatic',
    client = 'client',
}
export interface BankAccountNumber {
    value: string;
    source: BankAccountNumberSource;
}

export interface FileInformation {
    file: string;
    email: string;
    phone: string;
    date: string;
    location: string;
    ip: string;
    authentication: string;
    property_address?: string;
}

type ManualSource = 'manual';
type AutoSource = 'automatic';
type Source = ManualSource | AutoSource;

export interface BAVRequestAccountHolder {
    first_name: string;
    last_name: string;
    ssn: string;
    first_name_source: Source;
    last_name_source: Source;
    triggering_client: boolean;
    ssn_source: ManualSource;
}

export type AccountType = 'business' | 'personal';
export type NullSafeAccountTypes = AccountType | null;

export interface BankAccountVerificationRequest {
    routing_number: string;
    account_number: string;
    routing_number_source: Source;
    account_number_source: Source;
    additional_information: string;
    business_name?: string;
    ein?: string;
    client_address: string;
    client_zip: string;
    client_zip_source: Source;
    account_holders: BAVRequestAccountHolder[];
}

export interface BankAccountCreationRequest {
    owner_name: string;
    owner_address: string;
    bank_name: string;
    account_number: string;
    account_number_confirmation: string;
    routing_number: string;
    file_id: string;
    notes: string;
    verification_service_down: boolean;
}

export type BankAccountCreationResponse = string;

export type BankAccountVerificationResult = {
    status: 'success' | 'error';
    'bav-uuid'?: string;
};

export type BAVFormData = {
    wire_routing_number: string;
    routing_number_source: Source;
    account_holder_name?: string;
    account_number: string;
    account_number_source: Source;
    account_number_confirmation: string;
    client_address: string;
    client_zip: string;
    client_zip_source: Source;
    account_type: string;
    bank_name: string | null;
    bank_account_type: NullSafeAccountTypes;
    additional_information: string;
    valid_route_number: boolean;
    business_name?: string;
    ein?: string;
    account_holders: BavFormAccountHolder[];
};

export type RoutingNumberVerificationResult = {
    valid: boolean;
    verificationServiceDown: false;
    bank_name: string;
    bank_address: {
        line1?: string;
        line2?: string;
        locality?: string;
        region?: string;
        postal_code?: string;
        country?: string;
    };
};

export type AutoBankAccountHolderNames = {
    name: string;
};

export type booleanNumber = 0 | 1;
export type EmailType = 'primary' | 'secondary' | 'other';

export type BAVAddress = {
    bank_account_owner_id: string;
    created_at: string;
    updated_at: string;
    is_primary: booleanNumber;
    street: string;
    city: string;
    region: string;
    postal_code: string;
    country: string;
};

export type BAVEmail = {
    bank_account_owner_id: string;
    created_at: string;
    updated_at: string;
    is_primary: booleanNumber;
    email: string;
    type: EmailType;
};

export type BAVPhones = {
    bank_account_owner_id: string;
    created_at: string;
    updated_at: string;
    is_primary: booleanNumber;
    phone: string;
    type: string;
};

export type BAVName = {
    bank_account_owner_id: string;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
    is_primary: booleanNumber;
    name: string; // comes in from plaid as a single string
};

export type Owner = {
    addresses: BAVAddress[];
    bank_account_id: string;
    bank_account_type: string;
    created_at: string;
    deleted_at?: string;
    emails: BAVEmail[];
    names: BAVName[];
    phones: BAVPhones[];
};

export type BankAccountInfoResult = {
    ach_account: string;
    ach_routing: string;
    institution: string;
    institution_id: string;
    account_number_last_four?: string;
    subtype: string;
    name: string;
    notes?: string;
    owners: Owner[];
    source: string;
    type: string;
    wire_routing: string;
};

export type RoutingNumberVerificationResponse = RoutingNumberVerificationResult;
