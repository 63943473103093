<template>
    <div class="w-80 min-w-80">
        <div
            class="text-content-primary text-xl font-semibold mb-2"
            v-if="$slots.title"
        >
            <slot name="title"></slot>
        </div>
        <slot></slot>
    </div>
</template>

<script lang="ts" setup></script>
