import type {
    CreateEarlyDepositErrorPayload,
    CreateEarlyDepositRequest,
    CreateEarlyDepositResponse,
    EarlyDeposit,
    EarlyDepositTableResponse,
} from '@/api/interfaces/early-deposit-types';
import { AfterFetchContext, useFetch } from '@vueuse/core';
import { computed, nextTick, reactive, ref } from 'vue';

type RequestInit = Parameters<typeof useFetch>[1];
type UseFetchParams = Exclude<Parameters<typeof useFetch>[2], undefined>;

export const useExpandedRows = (() => {
    const rowIdToBoolMap: Record<EarlyDeposit['id'], boolean> = {};
    const expandedRows = ref(rowIdToBoolMap);
    const resetExpandedRows = () => {
        expandedRows.value = {};
    };
    const refreshExpandedRows = () => {
        const copy = { ...expandedRows.value };
        resetExpandedRows();
        nextTick(() => {
            expandedRows.value = copy;
        });
    };

    return () => ({
        expandedRows,
        resetExpandedRows,
        refreshExpandedRows,
    });
})();

export const useCreateEarlyDeposit = () => {
    const { isFetching, post } = useFetch('/user/early-deposit', { immediate: false }).json<CreateEarlyDepositResponse>();

    return {
        isFetchingEarlyDeposit: isFetching,
        createEarlyDeposit: (payload: CreateEarlyDepositRequest) => post(payload),
    };
};

export const useEarlyDepositsPaged = (() => {
    const initialData: EarlyDepositTableResponse = {
        data: [],
        links: {
            first: '',
            last: '',
            next: '',
            prev: '',
        },
        meta: {
            current_page: 0,
            from: 0,
            last_page: 0,
            links: [],
            path: '',
            per_page: 0,
            to: 0,
            total: 0,
        },
    };

    const tablePageNum = ref(1);
    const reactivePageData = reactive({ emds: initialData.data });
    const getUrl = () => `/user/early-deposit/table?page=${tablePageNum.value}&per_page=25`;
    const useFetchOpts = {
        initialData,
        refetch: true,
        beforeFetch({ cancel }) {
            if (!window.location.pathname.includes('/user/early-deposit')) {
                cancel();
            }
        },
        afterFetch(ctx: AfterFetchContext<EarlyDepositTableResponse>) {
            const pageResponseData = ctx.data;
            reactivePageData.emds = pageResponseData?.data ?? [];
            return ctx;
        },
        onFetchError(ctx) {
            const { error } = ctx;
            if (error) {
                const errorMessage = 'Error fetching a page of early deposits (EMDs):\n';
                console.warn(errorMessage, error);
                window.Bugsnag?.notify(new Error(error, { cause: errorMessage }));
            }
            return ctx;
        },
    } satisfies UseFetchParams;

    const useFetchReturn = useFetch(getUrl, useFetchOpts).get().json<EarlyDepositTableResponse>();
    const lastResponseData = useFetchReturn.data;
    const earlyDepositsPage = computed(() => lastResponseData.value?.data ?? initialData.data);
    const paginatorInfo = computed(() => lastResponseData.value?.meta ?? initialData.meta);
    const emdIdToArrayIndex = computed(() => {
        return earlyDepositsPage.value.reduce<Record<EarlyDeposit['id'], number>>((acc, emd, idx) => {
            acc[emd.id] = idx;
            return acc;
        }, {});
    });

    // const deleteLocalEmd = (deletedEmdId: EarlyDeposit['id']) => {
    //     if (!reactivePageData.emds.length) return;

    //     const indexInData = emdIdToArrayIndex.value[deletedEmdId];
    //     reactivePageData.emds.splice(indexInData, 1);
    // }

    const updateLocalEmd = (updatedEmd: Partial<EarlyDeposit>) => {
        if (!reactivePageData.emds.length) return;

        const indexInData = emdIdToArrayIndex.value[updatedEmd.id!];
        Object.assign(reactivePageData.emds[indexInData], updatedEmd);
    };

    return () => ({
        tablePageNum,
        reactivePageData,
        paginatorInfo,
        pageFetchError: useFetchReturn.error,
        updateLocalEmd,
        refetchCurrentPage: useFetchReturn.execute,
    });
})();

export const useDeleteEarlyDeposit = (() => {
    const deleteEmdRef = ref<EarlyDeposit>();

    const getUrl = computed(() => `/user/early-deposit/${deleteEmdRef.value?.id}`);
    const options: UseFetchParams = {
        immediate: false,
        beforeFetch: ({ cancel }) => {
            if (!deleteEmdRef.value?.id) {
                console.warn('[useDeleteEarlyDeposit] Fetch cancelled - No EMD id set');
                cancel();
            }
        },
        onFetchError: ctx => {
            const { error, response } = ctx;
            const responseJSON = JSON.stringify(response?.json(), undefined, 2);
            const errorMessage = `EMD could not be deleted. Server response (code: ${response?.status}):\n${responseJSON}`;

            console.warn(errorMessage, error);
            window.Bugsnag?.notify(
                new Error(error, { cause: errorMessage }),
                (evt) => {
                    evt.addMetadata('emd object', deleteEmdRef.value ?? { undefined: undefined });
                },
            );
            return ctx;
        },
    };
    const useFetchReturn = useFetch(getUrl, options).delete().json<EarlyDeposit>();

    return () => ({
        data: useFetchReturn.data,
        error: useFetchReturn.error,
        isFetching: useFetchReturn.isFetching,
        response: useFetchReturn.response,
        statusCode: useFetchReturn.statusCode,
        runDelete: () => useFetchReturn.execute(),
        deleteEmdRef,
        clearDeleteRef: () => {
            deleteEmdRef.value = undefined;
        },
        setDeleteRef: (emd: EarlyDeposit) => {
            deleteEmdRef.value = emd;
        },
    });
})();

export const useUpdateEarlyDeposit = (() => {
    const updateEmdRef = ref<EarlyDeposit>();
    const getUrl = computed(() => `/user/early-deposit/${updateEmdRef.value?.id}`);
    const options: UseFetchParams = {
        immediate: false,
        beforeFetch: ({ cancel }) => {
            if (!updateEmdRef.value?.id) {
                console.warn('[useUpdateEarlyDeposit] Fetch cancelled - No EMD id set');
                cancel();
            }
        },
        onFetchError: ctx => {
            const { error, response } = ctx;
            const responseJSON = JSON.stringify(response?.json(), undefined, 2);
            const errorMessage = `EMD could not be updated. Server response (code: ${response?.status}):\n${responseJSON}`;

            console.warn(errorMessage, error);
            window.Bugsnag?.notify(
                new Error(error, { cause: errorMessage }),
                (evt) => {
                    evt.addMetadata('emd object', updateEmdRef.value ?? { undefined: undefined })
                },
            );
            return ctx;
        },
    };
    const useFetchReturn = useFetch(getUrl, options).json<EarlyDeposit>();

    return () => ({
        data: useFetchReturn.data,
        error: useFetchReturn.error,
        isFetching: useFetchReturn.isFetching,
        response: useFetchReturn.response,
        statusCode: useFetchReturn.statusCode,
        runUpdate: (payload: Pick<Partial<EarlyDeposit>, 'address' | 'deposit_amount'>) => useFetchReturn.put(payload).execute(),
        updateEmdRef,
        clearUpdateRef: () => {
            updateEmdRef.value = undefined;
        },
        setUpdateRef: (emd: EarlyDeposit) => {
            updateEmdRef.value = emd;
        },
    });
})();

// export const useEditEarlyDeposit = () => {
//     const emdId = ref<string>('');
//
//     const getUrl = computed(() => `/user/early-deposit/${emdId.value}`);
//     const options: UseFetchParams = {
//         immediate: false,
//         beforeFetch: ({ cancel }) => {
//             if (!emdId.value) cancel();
//         },
//     };
//     const useFetchResources = useFetch(getUrl, options).json<EarlyDeposit>();
//
//     return {
//         data: useFetchResources.data,
//         error: useFetchResources.error,
//         isFetching: useFetchResources.isFetching,
//         response: useFetchResources.response,
//         statusCode: useFetchResources.statusCode,
//         updateEarlyDeposit: (payload: Pick<EarlyDeposit, 'id' | 'address' | 'deposit_amount'>) => {
//             emdId.value = payload.id;
//             return useFetchResources.put(payload).execute();
//         },
//         deleteEarlyDeposit: (id: EarlyDeposit['id']) => {
//             emdId.value = id;
//             return useFetchResources.delete().execute();
//         },
//     };
// };
//
// export const useEarlyDepositRowAction = () => {
//     const emdId = ref<string>('');
//     const clientId = ref<number>(0);
//     const getUrl = computed(() => `/user/early-deposit/${emdId.value}/client/${clientId.value}`);
//     const options: UseFetchParams = {
//         immediate: false,
//         beforeFetch: ({ cancel }) => {
//             if (!emdId.value) cancel();
//         },
//     };
//     const useFetchResources = useFetch(getUrl, options).json<EarlyDeposit>();
//     return {
//         data: useFetchResources.data,
//         error: useFetchResources.error,
//         isFetching: useFetchResources.isFetching,
//         response: useFetchResources.response,
//         statusCode: useFetchResources.statusCode,
//         deleteEarlyDepositClient: (
//             EarlyDepositId: EarlyDeposit['id'],
//             EarlyDepositClientId: EarlyDeposit['clients'][0]['id'],
//         ) => {
//             emdId.value = EarlyDepositId;
//             clientId.value = EarlyDepositClientId;
//             return useFetchResources.delete().execute();
//         },
//     };
// };

export const downloadEarlyDepositClientReceipt = (paymentId: PreFilePayment['id']) => {
    return `/user/early-deposit/payment/${paymentId}/download-receipt`;
};

const useApproveEarlyDeposit = () => {
    const preFilePayment = ref<string>();
    const postUrl = computed(() => `/user/early-deposit/payment/${preFilePayment.value}/release`);
    const useFetchResources = useFetch(postUrl).text();
    const { refetchCurrentPage } = useEarlyDepositsPaged();

    return {
        data: useFetchResources.data,
        error: useFetchResources.error,
        isFetching: useFetchResources.isFetching,
        response: useFetchResources.response,
        statusCode: useFetchResources.statusCode,
        approveEarlyDepositPayment: (PreFilePayment: EarlyDeposit['clients'][0]['pre_file_payments'][0]['id']) => {
            preFilePayment.value = PreFilePayment;
            useFetchResources
                .post()
                .text()
                .then(() => refetchCurrentPage());
        },
    };
};

const useRejectEarlyDeposit = () => {
    const preFilePayment = ref<string>();
    const postUrl = computed(() => `/user/early-deposit/payment/${preFilePayment.value}/reject`);
    const useFetchResources = useFetch(postUrl).text();
    const { refetchCurrentPage } = useEarlyDepositsPaged();

    return {
        data: useFetchResources.data,
        error: useFetchResources.error,
        isFetching: useFetchResources.isFetching,
        response: useFetchResources.response,
        statusCode: useFetchResources.statusCode,
        rejectEarlyDepositPayment: (PreFilePayment: EarlyDeposit['clients'][0]['pre_file_payments'][0]['id']) => {
            preFilePayment.value = PreFilePayment;
            useFetchResources
                .post()
                .text()
                .then(() => refetchCurrentPage());
        },
    };
};

const useRerequestEarlyDeposit = () => {
    const emdId = ref<EarlyDeposit['id']>();
    const client = ref<string>();
    const postUrl = computed(() => `/user/early-deposit/${emdId.value}/file/${client.value}/re-notify`);
    const useFetchResources = useFetch(postUrl).text();

    return {
        data: useFetchResources.data,
        error: useFetchResources.error,
        isFetching: useFetchResources.isFetching,
        response: useFetchResources.response,
        statusCode: useFetchResources.statusCode,
        rerequestEarlyDeposit: (earlyDepositId: EarlyDeposit['id'], clientId: EarlyDeposit['clients'][0]['id']) => {
            emdId.value = earlyDepositId;
            client.value = clientId;
            return useFetchResources.post().text();
        },
    };
};

export const getEmdActionMenu = (
    paymentInfo: PreFilePayment,
    earlyDepositId: EarlyDeposit['id'],
    clientId: EarlyDeposit['clients'][0]['id'],
) => {
    if (paymentInfo.status === 'Held') {
        return [
            {
                items: [
                    {
                        label: 'View Deposit Info',
                        url: downloadEarlyDepositClientReceipt(paymentInfo.id),
                    },
                    {
                        label: 'Approve Early Deposit',
                        command: () => {
                            const approveEarlyDepositPayment = useApproveEarlyDeposit();
                            approveEarlyDepositPayment.approveEarlyDepositPayment(paymentInfo.id);
                        },
                    },
                    {
                        label: 'Reject Early Deposit',
                        command: () => {
                            const rejectEarlyDepositPayment = useRejectEarlyDeposit();
                            rejectEarlyDepositPayment.rejectEarlyDepositPayment(paymentInfo.id);
                        },
                    },
                ],
            },
        ];
    }
    if (paymentInfo.status === 'Failed') {
        return [
            {
                items: [
                    {
                        label: 'View Deposit Info',
                        url: downloadEarlyDepositClientReceipt(paymentInfo.id),
                    },
                    {
                        label: 'Re-request Early Deposit',
                        command: () => {
                            const rerequestEarlyDeposit = useRerequestEarlyDeposit();
                            rerequestEarlyDeposit.rerequestEarlyDeposit(earlyDepositId, clientId);
                        },
                    },
                ],
            },
        ];
    }
};

/**
 * In an effort to speed things along for Early Deposit (EMD), the seemingly
 * similar [Create Form UI]({@link file://./../../../views/partials/user/addFile.blade.php})
 * was largely reused. Unfortuntately, the server-side error validation
 * for the Create File route returns element selectors for specific parts
 * of that page. The error response for EMD was not created when that convention
 * was known, so this function translates the EMD error response into something
 * the validation messages function of the Create File route can handle. This has
 * saved us so much time.
 */
export const makeErrorResponseCompatibleWithCreateFileValidation = async (response: Response) => {
    let data: CreateEarlyDepositErrorPayload;

    try {
        data = await response.json();
    } catch (err: any) {
        window.Bugsnag?.notify(new Error(err, {
            cause: 'Early Deposit error response could not be converted to legacy format',
        }), (evt) => {
            evt.addMetadata('response json', data);
        });
        return {};
    }

    if (!('errors' in data)) return {};

    const compatiblePayload: { errors: Partial<Record<string, string[]>> } = data;
    const digitRegex = /\d+/;
    const clientEmailRegex = /clients\.\d+\.email/g;
    const clientPhoneRegex = /clients\.\d+\.phone/g;
    const clientRoleRegex = /clients\.\d+\.role/g;
    const notifyingUsersRegex = /notifying_users\.\d+/g;

    for (const [errorKey, validationMsg] of Object.entries(data.errors)) {
        const idx = digitRegex.exec(errorKey)?.[0];
        if (!idx) continue;

        let compatibleKey = '';
        let compatibleMsg: string[] = [];

        if (clientEmailRegex.test(errorKey)) {
            compatibleKey = `email.${idx}`;
            compatibleMsg = validationMsg?.map(msg => msg.replaceAll(clientEmailRegex, compatibleKey)) ?? [];
        } else if (clientPhoneRegex.test(errorKey)) {
            compatibleKey = `phone.${idx}`;
            compatibleMsg = validationMsg?.map(msg => msg.replaceAll(clientPhoneRegex, compatibleKey)) ?? [];
        } else if (clientRoleRegex.test(errorKey)) {
            compatibleKey = `role.${idx}`;
            compatibleMsg = validationMsg?.map(msg => msg.replaceAll(clientRoleRegex, compatibleKey)) ?? [];
        } else if (notifyingUsersRegex.test(errorKey)) {
            compatibleKey = `notifying_users.${idx}`;
            compatibleMsg = validationMsg?.map(msg => msg.replaceAll(notifyingUsersRegex, compatibleKey)) ?? [];
        }

        if (compatibleKey) {
            delete data.errors[errorKey as keyof typeof data.errors]; // mutate the original data so thath any errors left behind may still be shown to the user
            compatiblePayload.errors[compatibleKey] = compatibleMsg;
        }
    }

    return compatiblePayload;
};
