<template>
    <header>
        <div class="legacy-md:w-full mx-auto font-inter">
            <div
                class="relative flex items-center justify-between"
                :class="portal === 'employee' ? 'py-1' : ''"
            >
                <div class="mx-2 flex-none overflow-hidden legacy-md:w-auto">
                    <a :href="homeLink">
                        <template v-if="portal === 'client'">
                            <div class="py-5 mt-1 pr-2 w-full mx-3">
                                <ClLogo />
                            </div>
                        </template>
                        <template v-if="portal === 'customer'">
                            <div class="py-4 pl-3 pr-2 w-full hidden legacy-md:block">
                                <ClLogo
                                    :width="180"
                                    :height="40"
                                />
                            </div>
                            <div class="py-5 mt-1 pr-2 w-full legacy-md:hidden">
                                <ClLogo />
                            </div>
                        </template>
                        <template v-if="portal === 'employee'">
                            <ClEmployeeLogo />
                        </template>
                    </a>
                </div>
                <slot name="middle"> </slot>
                <slot name="navigation"> </slot>
            </div>
        </div>
    </header>
    <slot name="warning"></slot>
</template>

<script lang="ts" setup>
import ClEmployeeLogo from '@/components/Icons/ClEmployeeLogo.vue';
import ClLogo from '@/components/Icons/ClLogo.vue';
import { computed } from 'vue';

const props = withDefaults(defineProps<{ portal: 'employee' | 'customer' | 'client'; isLoginProcessRoute: boolean }>(), {
    portal: 'employee',
});
defineEmits(['login', 'logout', 'createAccount']);
defineSlots<{ csrfField(): void; warning(): void; navigation(): void; middle(): void }>();

const homeLink = computed(() => {
    if (props.portal === 'client') {
        return props.isLoginProcessRoute ? '/login' : '/client/home';
    }
    if (props.portal === 'customer') {
        return props.isLoginProcessRoute ? '/login' : '/user/home';
    }
    return '/accounts';
});
</script>

<style scoped>
a:hover {
    color: #00487d;
}
</style>
