<template>
    <Dialog
        v-model:visible="dialogOpen"
        modal
        position="center"
        :draggable="false"
        :dismissableMask="true"
        :closable="true"
        :close-on-escape="true"
        :showHeader="false"
        :block-scroll="false"
        :pt="{
            root: 'p-0 xs:w-full max-w-7xl rounded-t-lg overflow-hidden',
            content: 'px-0 overflow-hidden ',
            header: 'h-0 p-1',
        }"
        close-icon=" "
        :pt-options="{ mergeProps: true }"
    >
        <template #closeicon></template>
        <BankAccountVerificationReport
            v-if="ready"
            :reportResult
            :currentReportId
            :still-processing
            :hasError="errorWithReport"
            @close="closeModal"
        />
        <ModalLoader v-else>
            <template #loadingText>
                <div class="text-base text-neutral-500">Loading Bank Account Verification Report</div>
            </template> 
        </ModalLoader>
    </Dialog>
</template>

<script lang="ts" setup>
import type { BAVReturnData } from '@/api/interfaces/bank-account-verification.api.d';
import ModalLoader from '@/components/Components/ModalLoader.vue';
import { useBankAccountVerificationService } from '@/services/bank-account-verification.service';
import { BRIDGE_EVENT_EMITTER, BRIDGE_EVENTS } from '@/shared/constants/event-emitter';
import type Emittery from 'emittery';
import Dialog from 'primevue/dialog';
import { inject, ref } from 'vue';
import BankAccountVerificationReport from './BankAccountVerificationReport.vue';

/** This is a wrapper for the BankAccountVerificationReport component. It exists as an entry point
 * while we are using a vue sprinkle to instantiate a vue app in the old jquery code.
 * The handling of events from external code should live here to keep components down unaware of that.
 * It should be kept thin and not contain business logic and should be removed once
 * we have a proper SPA replacing the current file management page or equivalent
 */

const currentReportId = ref<string>();
const ready = ref(false);
const dialogOpen = ref(false);
const bridgeEventEmitter = inject<Emittery>(BRIDGE_EVENT_EMITTER);

const bavService = useBankAccountVerificationService();
const reportResult = ref();
const errorWithReport = ref(false);
const stillProcessing = ref(false);

bridgeEventEmitter?.on(BRIDGE_EVENTS.BAV.CHANGE_CURRENT_BAV_REPORT, async ({ bankAccountId }: { bankAccountId: string }) => {
    currentReportId.value = undefined;
    reportResult.value = null;
    ready.value = false;
    if (!bankAccountId) {
        errorWithReport.value = false;
        dialogOpen.value = false;
        return;
    }
    currentReportId.value = bankAccountId;
    getReportByBankAccountId(bankAccountId);
    dialogOpen.value = true;
});

async function getReportByBankAccountId(bankAccountId: string) {
    try {

        const { data, statusCode } = await bavService.getReportById(bankAccountId);
        if (!data || (!statusCode || statusCode >= 400)) {
            throw new Error('Failed to get report, unexpected response');
        }
        stillProcessing.value = statusCode === 202;

        if (!stillProcessing.value) {
            errorWithReport.value = false;
            reportResult.value = data as BAVReturnData;
        }

    } catch (error) {
        errorWithReport.value = true;
    }
    ready.value = true;
}

function closeModal() {
    dialogOpen.value = false;
}
</script>
