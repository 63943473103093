<template>
    <div>
        <div class="text-xl font-semibold">Text and background colors</div>
        <div class="grid grid-flow-row grid-cols-3 gap-2 mb-16">
            <div
                v-for="contentColor in textColors"
                :key="contentColor"
                class="flex flex-col"
            >
                <div class="flex flex-row gap-2">
                    <div
                        class="p-2 rounded"
                        :class="[`text-${contentColor} bg-primary`]"
                    >
                        Veni
                    </div>
                    <div
                        class="p-2 rounded"
                        :class="[`text-${contentColor} bg-secondary`]"
                    >
                        Vidi
                    </div>
                    <div
                        class="p-2 rounded"
                        :class="[`text-${contentColor} bg-gray-900`]"
                    >
                        Vici
                    </div>
                </div>
                <code>{{ contentColor }}</code>
            </div>
        </div>
        <div class="text-xl font-semibold">Color families</div>
        <div
            v-for="colorFamily in colorFamilies"
            :key="colorFamily"
        >
            <div class="text-xl font-semibold">{{ colorFamily }}</div>
            <div class="grid grid-flow-row grid-cols-10 gap-2">
                <div
                    v-for="colorWeight in colorWeights"
                    :key="colorWeight"
                    class="p-2"
                >
                    <div
                        class="rounded-lg"
                        :class="[`bg-${colorFamily}-${colorWeight}`, 'p-2 h-8']"
                    ></div>
                    <code>{{ colorWeight }}</code>
                </div>
            </div>
        </div>
        <div class="text-xl font-semibold my-6">Divider</div>
        <div class="h-3 w-full bg-divide">
            <div class="grid grid-cols-3 divide-default gap-2 divide-x-8 w-1/2">
                <div>01</div>
                <div>02</div>
            </div>
        </div>
        <div class="text-xl font-semibold my-6">Legacy color families</div>
        <div
            v-for="colorFamily in ['primary', 'secondary']"
            :key="colorFamily"
        >
            <div class="text-xl font-semibold">{{ colorFamily }}</div>
            <div class="grid grid-flow-row grid-cols-10 gap-2">
                <div
                    v-for="colorWeight in colorWeights"
                    :key="colorWeight"
                    class="p-2"
                >
                    <div
                        class="rounded-lg"
                        :class="[`bg-${colorFamily}-${colorWeight}`, 'p-2 h-8']"
                    ></div>
                    <code>{{ colorWeight }}</code>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
/** Other color aliases exist but are not part of the design system */
const colorFamilies = [
    'color-primary',
    'color-gray',
    'color-green',
    'color-light-blue',
    'color-orange',
    'color-yellow',
    'color-red',
];
const colorWeights = ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'];

const textColors = [
    'content-primary',
    'content-secondary',
    'content-inverse',
    'content-error',
    'content-link',
    'content-disabled',
];
</script>
