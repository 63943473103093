import MultiFactorAuthenticationSend from '@/company-portal/pages/MultiFactorAuthenticationSend.vue';
import MultiFactorAuthenticationVerify from '@/company-portal/pages/MultiFactorAuthenticationVerify.vue';
import CompanyPortalLayout from '@/layouts/CompanyPortalLayout.vue';

import IdentityVerificationReportWrapper from './Components/IdentityVerificationReport/IdentityVerificationReportWrapper.vue';

import BankAccountVerificationReportWrapper from './Components/BankAccountVerification/BankAccountVerificationReportWrapper.vue';
import IdentityVerificationReviewWrapper from './Components/IdentityVerificationReview/IdentityVerificationReviewWrapper.vue';

import StateSelector from '@/employee-portal/pages/accounts/forms/StateSelector.vue';
import ClHeader from './Navigation/ClHeader.vue';
import CompanyNavigation from './Navigation/top-nav/CompanyNavigation.vue';
import EmployeeNavigation from './Navigation/top-nav/EmployeeNavigation.vue';
import ShadowButton from './Navigation/top-nav/ShadowButton.vue';
import ShadowWarning from './Navigation/top-nav/ShadowWarning.vue';
import AccountActions from './PageSections/AccountActions.vue';
import EarlyDepositForm from './Views/Company/EarlyDepositForm.vue';
import ViewEarlyDepositTable from './Views/Company/ViewEarlyDepositTable.vue';
import DesignSamples from './design-system/design-samples/DesignSamples.vue';

/*
  This list contains components that are used as entry points when initVueSprinkle is used.
  Descendant components are resolved as usual
  When using them in blade templates, declare the component tag using <kebab-case />
*/
export const allComponents = {
    ClHeader,
    CompanyPortalLayout,
    EarlyDepositForm,
    ViewEarlyDepositTable,
    EmployeeNavigation,
    CompanyNavigation,
    AccountActions,
    ShadowButton,
    ShadowWarning,
    MultiFactorAuthenticationSend,
    MultiFactorAuthenticationVerify,
    IdentityVerificationReportWrapper,
    IdentityVerificationReviewWrapper,
    BankAccountVerificationReportWrapper,
    DesignSamples,
    StateSelector,
};
