import { ButtonContext, ButtonProps } from 'primevue/button';
import { PrimeVuePTOptions } from 'primevue/config';
import Tailwind from 'primevue/passthrough/tailwind';

/** Correspondence between the severity property and a theme color */
const severityColors = new Map([
    ['primary', 'color-primary-600'],
    ['secondary', 'secondary-600'],
    ['success', 'color-primary-600'],
    ['info', 'color-primary-600'],
    ['warning', 'warning-500'],
    ['help', 'color-primary-600'],
    ['danger', 'destructive-500'],
]);

export const ButtonDesign = {
    button: {
        ...Tailwind.button,
        root: ({ props, context }: { props: ButtonProps; context: ButtonContext }) => {
            const commonClass = ' rounded p-3 ';

            const themeColor =
                (props.severity ? severityColors.get(props.severity) : severityColors.get('primary')) ||
                severityColors.get('primary');
            const stateClass = context.disabled ? ' opacity-30 ' : '';

            const defaultBorder = ` border-${themeColor} border-l border-r border-t border-b border-solid `;
            // filled, with border, text white
            let buttonStyledClass = ` bg-${themeColor} text-white border-2 ` + defaultBorder;
            if (props.outlined) {
                // not filled, with border, text colored
                buttonStyledClass = `bg-white text-${themeColor}` + defaultBorder;
            }
            if (props.link) {
                // not filled, without border, text colored
                buttonStyledClass = 'text-brand-primary border-0';
            }
            if (props.unstyled) {
                // no styles, rely on passed classes
                buttonStyledClass = '';
            }

            return { class: commonClass + stateClass + buttonStyledClass };
        },
        label: { class: 'text-base font-medium' },
    },
} as Pick<PrimeVuePTOptions, 'button'>;
