import { viewportWidth } from '@/components/Composables/getViewportSize';
import { computed } from 'vue';
import breakpoints from '../../../../tailwind.breakpoints.json';

const XS = 'xs';
const SM = 'sm';
const MD = 'md';
const LG = 'lg';
const XL = 'xl';

export const DS_BREAKPOINT_XS = parseInt(breakpoints.xs, 10);
export const DS_BREAKPOINT_SM = parseInt(breakpoints.sm, 10);
export const DS_BREAKPOINT_MD = parseInt(breakpoints.md, 10);
export const DS_BREAKPOINT_LG = parseInt(breakpoints.lg, 10);
export const DS_BREAKPOINT_XL = parseInt(breakpoints.xl, 10);

export const numericBreakpoints: Record<BreakpointSize, number> = {
    xs: parseInt(breakpoints.xs, 10),
    sm: parseInt(breakpoints.sm, 10),
    md: parseInt(breakpoints.md, 10),
    lg: parseInt(breakpoints.lg, 10),
    xl: parseInt(breakpoints.xl, 10),
}

export const invertedBreakpoints: Record<number, BreakpointSize> = {
    [numericBreakpoints.xs]: XS,
    [numericBreakpoints.sm]: SM,
    [numericBreakpoints.md]: MD,
    [numericBreakpoints.lg]: LG,
    [numericBreakpoints.xl]: XL,
};

export const getLargestBreakpointMatch = <T>(breakpointToValueMap: Partial<Record<BreakpointSize, T>>) => {
    const BREAKPOINT_SIZE_DESC: BreakpointSize[] = [XL, LG, MD, SM, XS] as const;
    for (const size of BREAKPOINT_SIZE_DESC) {
        if (size in breakpointToValueMap && matchMedia(`(min-width: ${breakpoints[size]})`).matches) {
            return breakpointToValueMap[size];
        }
    }
}

export const breakpointsToNums = (sizes: BreakpointSize[]): number[] =>
    sizes.map(size => numericBreakpoints[size]).sort()

export const activeBreakpointSize = computed<BreakpointSize>(() => {
    const innerWidth = viewportWidth.value;
    if (innerWidth >= DS_BREAKPOINT_XL) return XL;
    if (innerWidth >= DS_BREAKPOINT_LG) return LG;
    if (innerWidth >= DS_BREAKPOINT_MD) return MD;
    if (innerWidth >= DS_BREAKPOINT_SM) return SM;
    return XS;
});