import type { IDVStatus } from './identity-verification.api';

/** The root object returned by the API */
export type IdentityVerificationResult = {
    id: number;
    email: string;
    name: string;
    type: string;
    integration: string;
    raw_status: string;
    status: IDVStatus;
    notified_at: string;
    completed_at: string;
    pdfMapping: PDFMapping;
    ip_address: string;
    phone_caller_name: string;
    phone_carrier: string;
    images: {
        front: string;
        back: string;
    };
    reports: IdentityVerificationReports;
    supported_checks: IdentityVerificationSupportedChecks;
};

export type IdentityVerificationReports = {
    address_street_1: string;
    address_street_2: string | null;
    address_city: string;
    address_subdivision: string;
    address_postal_code: string;
    address_resolved_street_1: string;
    address_resolved_street_2: string | null;
    address_resolved_city: string;
    address_resolved_subdivision: string;
    address_resolved_postal_code: string | null;
    address_record_type: string;
    address_county_fips: string;
    address_county_name: string;
    address_residential_type: string | null;
    address_dpv_match: string;
    address_dpv_vacant: string | null;
    address_active: boolean;
    address_latitude: number;
    address_longitude: number;
    address_has_match: boolean;
    address_error_message: string | null;
    phone_type: string;
    phone_risk_level: string;
    phone_risk_recommendation: string;
    email_address: string;
    email_first_seen_days: string | null;
    email_last_seen_days: string | null;
    email_estimated_age_days: number;
    email_reputation: string;
    email_suspicious: boolean;
    email_reference_count: string | null;
    email_blocklisted: string | null;
    email_disposable: boolean;
    email_free_provider: string | null;
    email_spam: boolean;
    email_deliverable: boolean;
    email_domain_reputation: string | null;
    email_domain_age_days: number;
    government_id_number: string;
    government_id_issue_date: string;
    government_id_expiration_date: string;
    selected_id_class: string;
    full_name: string;
    date_of_birth: string;
    phone_number: string;
    social_security_number: string;
    verification_government_id_birthdate: string;
    verification_government_id_name_first: string;
    verification_government_id_name_last: string;
    verification_aamva_issuing_authority: string;
    verification_database_birthdate: string;
    verification_database_name_first: string;
    verification_database_name_last: string;
    verification_phone_carrier_birthdate: string;
    verification_phone_carrier_name_first: string;
    verification_phone_carrier_name_last: string;
    verification_phone_carrier_phone_number: string;
    selected_country_code: string;
};

export type IdentityVerificationSupportedChecks = {
    name: boolean;
    address: boolean;
    birthdate: boolean;
    national_id: boolean;
    phone_number: boolean;
    email_address: boolean;
};

export type CheckErrorRecommendation = {
    error: string;
    recommendation?: string;
};

/** The foundational entity that defines a check result, what text to show and errors */
export type CheckResult = {
    state: CheckResultState;
    suppressMatch?: boolean;
    stateText: string;
    text: string;
    infoList?: (string | CheckErrorRecommendation)[];
};

export type PDFMapping = {
    id: number;
    file: File;
    overallResult: OverallResult;
    knownYourCustomer: KnownYourCustomerAnalysis;
    govIdAnalysis: GovIDAnalysis;
    selfieVerification: SelfieVerificationAnalysis;
    additionalInformation: AdditionalInformationAnalysis;
    ipAddressResult: IPAddressAnalysis;
};

export type AdditionalInformationAnalysis = {
    geolocation: AdditionalInformationGeolocation;
    emailRisk: EmailRisk;
    phoneRisk: PhoneRisk;
};

export type EmailRisk = {
    email: CheckResult;
    firstSeen: CheckResult;
    estimatedAge: CheckResult;
    reputation: CheckResult;
    suspicious: CheckResult;
    disposable: CheckResult;
    spam: CheckResult;
    deliverable: CheckResult;
    domainAge: CheckResult;
};

export enum SourceKey {
    Manual = 'manual',
    Automatic = 'automatic',
    client = 'client',
}

export enum SourceLabel {
    Manual = 'Manual',
    Automatic = 'Automatic',
    Client = 'Client',
}

export enum CheckResultState {
    CautiousFailed = 'cautious_failed',
    Failed = 'failed',
    NotApplicable = 'not_applicable',
    Unverified = 'unverified',
    Passed = 'passed',
    NotFound = 'not_found',
}

export type AdditionalInformationGeolocation = {
    lat: number;
    lng: number;
    address: CheckResult;
    recordType: CheckResult;
    countryFips: CheckResult;
    country: CheckResult;
    propertyType: CheckResult;
    dpvMatch: CheckResult;
    dpvVacant: CheckResult;
    active: CheckResult;
};

export type PhoneRisk = {
    phoneNumber: CheckResult;
    phoneType: CheckResult;
    accountHolder: CheckResult;
    carrier: CheckResult;
    riskLevel: CheckResult;
    riskRecommendation: CheckResult;
    addressComparison: CheckResult;
    birthDateComparison: CheckResult;
};

export type File = {
    id: number;
    ref_number: string;
};

export type KnownYourCustomerAnalysis = {
    name: CheckResult;
    dateOfBirth: CheckResult;
    phone: CheckResult;
    deceased: CheckResult;
    address: CheckResult;
    ssn: CheckResult;
    mismatch: CheckResult;
    poboxDetection: CheckResult;
    addressDeliverableDetection: CheckResult;
    addressResidentialDetection: CheckResult;
    streetAddress: CheckResult;
    city: CheckResult;
    state: CheckResult;
    zipCode: CheckResult;
    country: CheckResult;
};

export type GovIDAnalysis = {
    isIdReadable: CheckResult;
    isBarcodeReadable: CheckResult;
    isIdAuthentic: CheckResult;
    isAamvaEligibleId: boolean;
    aamvaDisabledState: boolean;
    idNumber: CheckResult;
    fullName: CheckResult;
    currentAddress: CheckResult;
    dateOfBirth: CheckResult;
    expiryDate: CheckResult;
    issueDate: CheckResult;
    docFrontUrl: string;
    docBackUrl: string;
    issuingAuthority: string;
};

export type IPAddressAnalysis = {
    geolocation: IPAddressResultGeolocation;
    privacy: Privacy;
    network: Network;
};

export type IPAddressResultGeolocation = {
    lat: number;
    lng: number;
    ip: CheckResult;
    hostname: CheckResult;
    city: CheckResult;
    region: CheckResult;
    country: CheckResult;
    location: CheckResult;
};

export type Network = {
    name: CheckResult;
    domain: CheckResult;
    type: CheckResult;
};

export type Privacy = {
    vpn: CheckResult;
    proxy: CheckResult;
    tor: CheckResult;
    relay: CheckResult;
    hosting: CheckResult;
};

export type OverallResult = {
    overallStatus: CheckResult;
    allSections: CheckResult;
    knownYourCustomer: CheckResult;
    govIdAnalysis: CheckResult;
    selfieVerification: CheckResult;
};

export type SelfieVerificationAnalysis = {
    selfieAnalysisEnabled: boolean;
    selfieCheck: string;
    isSelfieProvided: CheckResult;
    isSelfieAuthentic: CheckResult;
    selfieMatchGovId: CheckResult;
    images: {
        left: string;
        right: string;
        front: string;
    };
};
