<template>
    <div
        v-if="!isSafari"
        class="flex gap-1 legacy-xl:gap-2 border-none pl-0"
    >
        <input
            v-for="index in codeLength"
            :key="index"
            type="text"
            :id="'input_' + index"
            v-model="code[index]"
            class="w-1/6 aspect-[13/15] rounded text-xl lg:text-2xl legacy-xl:text-3xl text-center border-2 border-brand-primary font-bold px-0"
            :class="{
                'border-red-500': error,
            }"
            @input.stop="handleInput"
            :autocomplete="index == 1 ? 'one-time-code' : false"
            inputmode="numeric"
            pattern="[0-9]*"
            @keypress="handleFieldKeypress"
            @keydown.delete="handleDelete"
            @paste="onPaste"
        />
    </div>
    <div
        v-else
        class="flex gap-1 legacy-xl:gap-2 border-none pl-0"
    >
        <input
            type="text"
            class="form-input w-full rounded"
            :class="{ 'border-red-500 border-2': error }"
            id="input_1"
            v-model="safariCode"
            inputmode="numeric"
            pattern="[0-9]*"
            @keypress="isNumber"
            @input.stop="safariInput"
            :maxlength="codeLength"
        />
    </div>
</template>

<script>
export default {
    name: 'CodeInput',
    props: {
        codeLength: {
            type: Number,
            default: 6,
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            code: {},
            safariCode: '',
            dataFromPaste: '',
            keysAllowed: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        };
    },
    mounted() {
        document.getElementById(`input_1`).focus();
    },
    methods: {
        handleFieldKeypress: function (event) {
            const keyPressed = event.key;
            if (!this.keysAllowed.includes(keyPressed)) {
                event.preventDefault();
            } else {
                this.isSafari ? void 0 : (event.currentTarget.value = '');
            }
        },
        handleInput: function (event) {
            let _a, _b;
            let inputType = event.inputType ?? 'insertFromPaste';

            let currentActiveElement = event.target;
            if (inputType === 'insertText') {
                (_a = currentActiveElement.nextElementSibling) === null || _a === void 0 ? void 0 : _a.focus();
            }
            if (!event.inputType) this.dataFromPaste = currentActiveElement.value;

            if (currentActiveElement.value.length > 1) {
                this.dataFromPaste = currentActiveElement.value;
                inputType = 'insertFromPaste';
            }

            if (inputType === 'insertFromPaste' && this.dataFromPaste) {
                for (let _i = 0, dataFromPaste_1 = this.dataFromPaste; _i < dataFromPaste_1.length; _i++) {
                    const num = dataFromPaste_1[_i];
                    const id = parseInt(currentActiveElement.id.split('_')[1]);
                    currentActiveElement.value = num;
                    this.code[id] = num;
                    if (currentActiveElement.nextElementSibling) {
                        currentActiveElement = currentActiveElement.nextElementSibling;
                        (_b = currentActiveElement.nextElementSibling) === null || _b === void 0 ? void 0 : _b.focus();
                    }
                }
            }
            this.$emit(
                'code',
                '' +
                    (this.code[1] ?? '') +
                    (this.code[2] ?? '') +
                    (this.code[3] ?? '') +
                    (this.code[4] ?? '') +
                    (this.code[5] ?? '') +
                    (this.code[6] ?? ''),
            );
        },
        handleDelete: function (event) {
            let _a;
            //keydown event = move to previous element then only delete number
            const value = event.target.value;
            const currentActiveElement = event.target;
            if (!value) (_a = currentActiveElement.previousElementSibling) === null || _a === void 0 ? void 0 : _a.focus();
        },
        onPaste: function (event) {
            let _a;
            this.dataFromPaste =
                (_a = event.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('text').trim().split('');
            if (this.dataFromPaste) {
                for (var _i = 0, dataFromPaste_2 = this.dataFromPaste; _i < dataFromPaste_2.length; _i++) {
                    var num = dataFromPaste_2[_i];
                    if (!this.keysAllowed.includes(num)) event.preventDefault();
                }
            }
        },
        safariInput: function () {
            this.$emit('code', this.safariCode);
        },
    },
    computed: {
        isSafari: function () {
            const userAgent = window.navigator.userAgent;
            return (
                /iP(ad|od|hone)/i.test(userAgent) && /WebKit/i.test(userAgent) && !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent)
            );
        },
    },
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
</style>
