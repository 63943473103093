import { PrimeVuePTOptions } from 'primevue/config';

export const MenubarDesign = {
    menubar: {
        root: { class: 'bg-inherit bg-white border-none focus:bg-white p-0 cursor-pointer' },
        content: { class: 'bg-white hover:bg-white border-none text-inherit hover:text-inherit' },
        menuitem: {
            class: 'bg-white border-none focus:bg-white',
        },
        button: {
            class: 'flex md:hidden w-8 h-8 rounded-full text-gray-600 dark:text-white/80 transition duration-200 ease-in-out cursor-pointer flex items-center justify-center no-underline hover:text-gray-700 dark:hover:text-white/80 hover:bg-gray-200 dark:hover:bg-gray-800/80 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)] focus:bg-white active:bg-white hover:bg-white',
        },
        submenu:
            'py-1 bg-white dark:bg-gray-900 border-0  md:shadow-md md:w-48 w-full static shadow-none md:absolute z-10 m-0 list-none',
    },
} as Pick<PrimeVuePTOptions, 'menubar'>;
