<template>
    <div
        :class="[value.state]"
        v-if="icon"
    >
        <font-awesome-icon
            size="md"
            :icon="`fa-solid fa-${icon}`"
        />
    </div>
</template>

<script lang="ts" setup>
import { CheckResultState, type CheckResult } from '@/api/interfaces/identity-verification-report.api';
import { computed } from 'vue';

const iconName = {
    [CheckResultState.CautiousFailed]: 'exclamation-triangle',
    [CheckResultState.Failed]: 'circle-xmark',
    [CheckResultState.Unverified]: 'exclamation-triangle',
    [CheckResultState.NotApplicable]: '',
    [CheckResultState.Passed]: 'check-circle',
    [CheckResultState.NotFound]: 'question-mark-circle',
} as const;

const props = defineProps<{ value: CheckResult }>();
const icon = computed(() => iconName[props.value.state] ?? 'question-mark-circle');
</script>

<style scoped>
.failed {
    color: #f43f5e;
}

.passed {
    color: #16a34a;
}

.needs_review {
    color: #000000;
}

.cautious_failed {
    color: #f59e0b;
}
</style>
