<template>
    <div class="w-3/4 p-2 mx-auto">
        <h1 class="mt-4 mb-6">Design System Samples</h1>
        <Accordion lazy>
            <AccordionTab
                header="Text Display Sizes"
                :pt="{
                    content: 'text-black',
                }"
            >
                <TextDisplaySizes />
            </AccordionTab>
            <AccordionTab header="Colors">
                <FoundationColors />
            </AccordionTab>
            <AccordionTab header="Grid">
                <GridBreakpoints />
            </AccordionTab>
            <AccordionTab header="Icons">
                <IconSample />
            </AccordionTab>
            <AccordionTab header="Form elements">
                <Accordion>
                    <AccordionTab header="DsTextInput">
                        <div class="grid grid-cols-2 gap-2">
                            <DsTextInput
                                type="text"
                                label="Full Name"
                                helper-text=""
                                v-model="inputTextValue"
                                @update:model-value="value => console.log(value)"
                            />
                            <DsTextInput
                                type="text"
                                label="Full Name"
                                helper-text="With helper text"
                                v-model="inputTextValue"
                            />
                            <DsTextInput
                                type="text"
                                label="Full Name"
                                helper-text="Required field"
                                required
                                v-model="inputTextValue"
                            />
                            <DsTextInput
                                type="text"
                                label="Full Name"
                                helper-text="Disabled field"
                                disabled
                                v-model="inputTextValue"
                            />
                            <DsTextInput
                                type="text"
                                label="Full Name"
                                helper-text="Readonly field"
                                readonly
                                v-model="inputTextValue"
                            />
                            <DsTextInput
                                type="text"
                                label="Full Name"
                                helper-text="Field with error"
                                error="Error message"
                                v-model="inputTextValue"
                            />
                            <DsTextInput
                                type="text"
                                label="Full Name"
                                helper-text="Field with error"
                                :error="true"
                                v-model="inputTextValue"
                            >
                                <template #error>A slotted error omg</template>
                            </DsTextInput>
                            <DsTextInput
                                type="text"
                                label="Full Name"
                                helper-text="Input with prefix and suffix slots and dynamic padding"
                                v-model="inputTextValue"
                            >
                                <template #leading>
                                    <font-awesome-icon icon="user" />
                                </template>
                                <template #trailing>
                                    <div>
                                        <font-awesome-icon icon="user" />
                                        <font-awesome-icon icon="house" />
                                        <font-awesome-icon icon="star" />
                                    </div>
                                </template>
                            </DsTextInput>
                        </div>
                    </AccordionTab>
                </Accordion>
            </AccordionTab>
            <AccordionTab header="Buttons">
                <DsButtonSamples />
            </AccordionTab>
            <AccordionTab header="Alert Banners">
                <AlertBannerSamples />
            </AccordionTab>
        </Accordion>
    </div>
</template>

<script lang="ts" setup>
import DsButtonSamples from '@ds/design-samples/DsButtonSamples/DsButtonSamples.vue';
import FoundationColors from '@ds/design-samples/FoundationColors.vue';
import GridBreakpoints from '@ds/design-samples/GridBreakpoints.vue';
import IconSample from '@ds/design-samples/IconSample.vue';
import TextDisplaySizes from '@ds/design-samples/TextDisplaySizes.vue';
import DsTextInput from '@ds/form/input/DsTextInput.vue';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { ref } from 'vue';
import AlertBannerSamples from './AlertBannerSamples.vue';

const inputTextValue = ref('Text sample');
</script>
