import { csrfToken } from '@/components/Utils/Helper';
import { BeforeFetchContext, createFetch, useFetch, UseFetchOptions } from '@vueuse/core';
import { ref } from 'vue';

type BeforeFetchReturns = ReturnType<Exclude<UseFetchOptions['beforeFetch'], undefined>>; // the defined return types of `UseFetchOptions['beforeFetch']`
type JWT = string;
type Portal = 'company' | 'client' | 'employee';
type BuildFetchOpts = {
    auth?: 'jwt',
    baseUrl?: string,
    onBeforeFetch?: (ctx: BeforeFetchContext, portal: Portal) => BeforeFetchReturns,
}

const TOKEN_TTL = 60 * 60 * 1000; // 1 hour
const csrfHeader = { 'X-CSRF-TOKEN': csrfToken?.value };
const jwtToken = ref<JWT>('');
const tokenTimestamp = ref<number>(0);

/** Sets the Authorization header of a request as a JWT Bearer token */
const decorateRequestWithJWT = async ({ options, cancel }: BeforeFetchContext, portal: Portal) => {
    const tokenExpired = Date.now() - tokenTimestamp.value > TOKEN_TTL;
    const jwtEndpoints = {
        client: '/jwt/client',
        company: '/jwt/user',
        employee: '/jwt/employee'
    } as const satisfies Record<Portal, string>;

    if (!jwtToken.value || tokenExpired) {
        const { data: newJwt, error: jwtError } = await useFetch(jwtEndpoints[portal], { headers: csrfHeader }).get().json<{ token: JWT }>();

        if (jwtError.value || !newJwt.value) {
            console.error(`Error fetching JWT for ${portal}.`);
            window.Bugsnag?.notify(new Error(jwtError.value, {
                cause: `Error fetching JWT for ${portal}.`,
            }), (evt) => {
                evt.addMetadata('response json', {
                    jwtError: jwtError.value,
                    newJwt: newJwt.value,
                })
            });
            cancel();
            return { options } satisfies BeforeFetchReturns;
        }

        tokenTimestamp.value = Date.now();
        jwtToken.value = newJwt.value.token;
    }

    options.headers = {
        ...options.headers,
        Authorization: `Bearer ${jwtToken.value}`,
    };

    return { options } satisfies BeforeFetchReturns;
}

function buildFetch(portal: Portal) {
    return (opts: BuildFetchOpts = {}) =>
        createFetch({
            baseUrl: opts.baseUrl,
            fetchOptions: {
                headers: csrfHeader,
            },
            options: {
                async beforeFetch(ctx) {
                    if (opts.auth === 'jwt') {
                        ctx.options = (await decorateRequestWithJWT(ctx, portal)).options;
                    }
                    return opts.onBeforeFetch?.(ctx, portal) ?? ctx;
                }
            }
        });
}

export const useEmployeeApi = buildFetch('employee');
export const useCompanyApi = buildFetch('company');
export const useClientApi = buildFetch('client');
