<template>
    <div class="ds-icon-frame inline-flex" :class="[`ds-icon-size-${size}`]">
        <slot>
            <font-awesome-icon v-if="icon" v-bind="faProps" />
        </slot>
    </div>
</template>

<script setup lang="ts">
import type { FontAwesomeIconProps } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

// Owing to a limitation of the Vue SFC compiler, if you want to use a native Font Awesome prop,
// you must add it explicitly. See https://vuejs.org/guide/typescript/composition-api#syntax-limitations
type DsIconProps = {
    color?: string,
    /** `icon` not needed if you slot in a custom icon. */
    icon?: FontAwesomeIconProps['icon'],
    size: 'sm' | 'md' | 'lg' | 'xl',
    spin?: FontAwesomeIconProps['spin'],
}

const props = withDefaults(
    defineProps<DsIconProps>(), {
        color: 'currentColor',
        icon: '',
    }
);

const faProps = computed(() => {
    const { color, size, ...faProps } = props;
    return faProps;
});
</script>

<style scoped lang="scss">
.ds-icon- {
    &frame {
        --ds-icon-color: v-bind(color);
        --ds-icon-gutter: 6px; // create a small amount of padding around the icon (3px for each side)

        > * {
            margin: auto; 
        }
    }

    &size- {
        &sm {
            @apply size-4;
            color: var(--ds-icon-color, currentColor);
            font-size: calc(theme('spacing.4') - calc(var(--ds-icon-gutter) / 2));
        }

        &md {
            @apply size-6;
            color: var(--ds-icon-color, currentColor);
            font-size: calc(theme('spacing.6') - var(--ds-icon-gutter));
        }

        &lg {
            @apply size-8;
            color: var(--ds-icon-color, currentColor);
            font-size: calc(theme('spacing.8') - var(--ds-icon-gutter));
        }

        &xl {
            @apply size-10;
            color: var(--ds-icon-color, currentColor);
            font-size: calc(theme('spacing.10') - var(--ds-icon-gutter));
        }
    }
}
</style>
