<template>
    <div v-if="normalizedErrorItems.length > 0">
        <CheckErrorContainer
            :indentation-level
            v-for="(v, k) in normalizedErrorItems"
            :class="{ 'border-b-0': k === normalizedErrorItems.length - 1 }"
            :key="v.error"
        >
            <ul class="list-none marker:text-gray-600 text-base text-gray-500 bg-transparent">
                <li>
                    <div>{{ v.error }}</div>
                    <div
                        style="margin-bottom: 0"
                        v-if="v.recommendation"
                    >
                        <div class="text-pop-600 inline-block">Recommendation:</div>
                        {{ v.recommendation }}
                    </div>
                </li>
            </ul>
        </CheckErrorContainer>
    </div>
</template>

<script lang="ts" setup>
import { CheckResult } from '@/api/interfaces/identity-verification-report.api';
import { computed } from 'vue';
import CheckErrorContainer from './CheckErrorContainer.vue';

const props = withDefaults(defineProps<{ value: CheckResult; indentationLevel?: number }>(), { indentationLevel: 2 });

const normalizedErrorItems = computed(() => {
    const infoList = props.value.infoList ?? undefined;
    if (!Array.isArray(infoList)) {
        window.Bugsnag?.notify(new Error('Unexpected infoList value in CheckErrorList.vue'), 
            (event) => {
                event.severity = 'info';
                event.addMetadata('Error Values', props.value);
            });
        return [];
    }
    return infoList.map(item => {
        if (typeof item === 'string') {
            return { error: item };
        }
        return { error: item.error, recommendation: item.recommendation };
    });
});
</script>
