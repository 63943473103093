<template>
<div class="form-group flex flex-wrap -mx-2 company-row">
    <label class="w-3/12 form-label px-2">Company</label>
    <div class="w-9/12 px-2">
        <div class="form-label p-0">
            <select name="company_id" id="select-company" data-id="company_id" aria-label="Select company">
                <option v-for="(company) in props.companies" :value="company.id">
                    {{ company.name }}
                </option>
            </select>
            <span id="company_id_error" data-id="company_id.error" class="form-control-error-description text-danger"></span>
        </div>
    </div>
</div>
<div class="form-group flex flex-wrap -mx-2 branch-row">
    <label class="w-3/12 form-label px-2">Branch</label>
    <div class="w-9/12 px-2">
        <div class="form-label p-0">
            <select name="branch_id" id="select-branch"  data-id="branch_id" aria-label="Select branch">
                <!-- {{-- Populated by jQuery --}} -->
            </select>
            <span id="branch_id_error" data-id="branch_id.error" class="form-control-error-description text-danger"></span>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
const props = defineProps<{
    companies: { id: string, name: string }[]
}>();
</script>