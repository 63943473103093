<template>
    <div
        class="grid gap-x-2 p-4 items-center rounded-lg border-2"
        :class="`${mode}-banner`"
        :aria-labelledby="bannerId"
    >
        <font-awesome-icon :icon="`fas fa-exclamation-${mode === 'error' ? 'triangle' : 'circle'}`" />
        <h6 :id="bannerId" class="font-semibold text-content-md">{{ title }}</h6>
        <p v-if="$slots.default" class="text-content-md mt-1 desc">
            <slot />
        </p>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

defineProps<{
    mode: 'warning' | 'error'
    title: string
}>();

const bannerId = computed<string>(() => `banner-title-${crypto.randomUUID()}`);
</script>

<style lang="scss" scoped>
.grid {
    grid-template-columns: min-content 1fr;
    grid-template-areas:
        'icon title'
        '.    desc';

    .desc {
        grid-area: desc;
    }
}

.warning-banner {
    @apply border-yellow-400 bg-yellow-200;
}

.error-banner {
    @apply border-color-red-600 bg-red-200;
}
</style>
