import moment from 'moment/moment';
import { computed, UnwrapRef } from 'vue';

type HelperModule = typeof import('@/components/Utils/Helper.ts');
type UnwrappedHelperModule = {
    [K in keyof HelperModule]: HelperModule[K] extends () => infer R ? R : UnwrapRef<HelperModule[K]>
}

export const getGlobalKeysForPiniaPatch = (overrides: Partial<UnwrappedHelperModule> = {}) => ({
    clientType: overrides.clientType ?? clientType.value,
    clientIsLoggedIn: overrides.clientIsLoggedIn ?? clientIsLoggedIn(),
    csrfToken: overrides.csrfToken ?? csrfToken.value,
    enableForPayments: overrides.enableForPayments ?? enableForPayments.value,
    clientId: overrides.clientId ?? clientId.value,
    clientFirstName: overrides.clientFirstName ?? clientFirstName.value,
    clientLastName: overrides.clientLastName ?? clientLastName.value,
    clientEmail: overrides.clientEmail ?? clientEmail.value,
    // clientFullName: overrides.clientFullName ?? clientFullName.value,
    titleLogoFolder: overrides.titleLogoFolder ?? titleLogoFolder.value,
});

// TODO: options refers to the global variable in resources/views/layouts/clientRoot.blade.php
// provide a better declaration for it in the context of client portal or pass this to the
// component / store directly
// NOTE: This helper has no defined function and is basically a big hand made store abstracting
// global variables

export const currentFile = computed<string | null>(() => options.current_file);

export const clientType = computed<string>(() => options.client_type);

export const clientIsLoggedIn = (): boolean => {
    return options.client_logged_in ?? false;
};

export const csrfToken = computed<string>(() => options.csrf_token);

export const enableForPayments = computed<boolean>(() => options.payments_enabled);

export const clientId = computed<string>(() => options.client_id);

export const clientFirstName = computed<string>(() => options.client_first);

export const clientLastName = computed<string>(() => options.client_last);

export const clientEmail = computed<string>(() => options.client_email);

export const clientFullName = computed<string>(() => `${clientFirstName.value} ${clientLastName.value}`.trim());

export const titleLogoFolder = computed<string>(() => options.title_logo_folder);

export const baseUrl = (url: string | null = null) => {
    return import.meta.env['VITE_BASE_URL'] + url;
};

export const isMediumScreen = computed(() => {
    return window.innerWidth >= 768;
});

export function setInputCursor(el: HTMLInputElement, position: number) {
    const setSelectionRange = function () {
        el.setSelectionRange(position, position);
    };
    if (el === document.activeElement) {
        setSelectionRange();
        setTimeout(setSelectionRange, 1); // Android Fix
    }
}

export function diffDaysForDate(holidays, date, format = 'MMM DD, YYYY', allowNegative = false) {
    // converting both dates to start of day so that we can do day to day comparisons w/o time
    let start = moment().startOf('day');
    let end = moment(date, format).startOf('day');
    let reversed = false;

    let businessDays = 0;

    // if we're allowing negative return values, and our start is after the end, flip them
    if (allowNegative && start > end) {
        const temp = start;
        start = end;
        end = temp;
        reversed = true;
    }

    while (start < end) {
        if (holidays.indexOf(start.format('YYYY-MM-DD') === 0)) {
            if ([1, 2, 3, 4, 5].indexOf(start.day()) >= 0) {
                businessDays++;
            }
        }
        start.add(1, 'd');
    }

    // if we flipped our start/end dates, then we want to return a negative value
    if (reversed) {
        businessDays = businessDays * -1;
    }

    return businessDays;
}
