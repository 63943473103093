export function splitName(name: string): { firstName: string; lastName: string } {
    const trimmedName = name.trim();
    const parts = trimmedName.split(/\s+/);
    const firstName = parts.shift() || '';
    const lastName = parts.join(' ') || '';
    return { firstName, lastName };
}

export const removeNonDigits = (value: unknown) => {
    if (!value || typeof value !== 'string') {
        return '';
    }
    return value.replaceAll(/\D/g, '');
}