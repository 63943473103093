<template>
    <VerificationContent :indentation-level="0">
        <VerificationGrid
            :indentation-level="0"
            :grid-cols="4"
            class="text-sm"
        >
            <template v-if="isBusinessReport">
                <CheckTextualResult
                    v-if="businessNameComputed"
                    label="Business Name:"
                    colorful-state
                    :inlineTextContent="true"
                    with-state-icon
                    :value="mapBavMatchToCheckResult(businessNameComputed)"
                />
                <CheckTextualResult
                    v-if="einComputed"
                    label="EIN Number:"
                    colorful-state
                    :inlineTextContent="true"
                    with-state-icon
                    :value="mapBavMatchToCheckResult(einComputed)"
                />
            </template>
            <CheckTextualResult
                label="Account Holder Mailing Address:"
                v-if="accountDetails.client_address"
                :colorful-state="true"
                :inlineTextContent="true"
                with-state-icon
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: accountDetails.client_address,
                    })
                "
            >
                <template #label>
                    <div class="w-full">Account Holder Mailing Address:</div>
                </template>
            </CheckTextualResult>
            <CheckTextualResult
                v-if="accountDetails.zip_code"
                label="Zip code:"
                :colorful-state="true"
                :inlineTextContent="true"
                with-state-icon
                :value="mapBavMatchToCheckResult(accountDetails?.zip_code)"
            />
            <hr class="col-span-full w-full h-1 border-b-gray-300" />
            <template
                v-for="(accountHolder, k) in accountHolderWithKey.account_holders"
                :key="accountHolder.key"
            >
                <div class="col-span-full font-bold text-xxs">Account holder {{ k + 1 }}</div>
                <CheckTextualResult
                    label="First Name:"
                    colorful-state
                    :inlineTextContent="true"
                    with-state-icon
                    :value="mapBavMatchToCheckResult(accountHolder.first_name)"
                />
                <CheckTextualResult
                    label="Last Name:"
                    colorful-state
                    :inlineTextContent="true"
                    with-state-icon
                    :value="mapBavMatchToCheckResult(accountHolder.last_name)"
                />
                <CheckTextualResult
                    v-if="!isBusinessReport"
                    label="SSN"
                    colorful-state
                    :inlineTextContent="true"
                    with-state-icon
                    :value="mapBavMatchToCheckResult(maskSSN(accountHolder.ssn, isBusinessReport))"
                />
                <hr class="col-span-full w-full h-1 border-b-gray-300 last:hidden" />
            </template>
        </VerificationGrid>
    </VerificationContent>
</template>
<script lang="ts" setup>
import {
    MatchResultState,
    SourceType,
    type BavReportAccountHolder,
    type BAVReturnData,
} from '@/api/interfaces/bank-account-verification.api.d';
import { CheckResultState } from '@/api/interfaces/identity-verification-report.api';
import {
    makeCheckResult,
    mapBavMatchToCheckResult,
} from '@/components/Components/BankAccountVerification/report-check-result-mapping';
import { computed } from 'vue';
import CheckTextualResult from '../../IdentityVerificationReport/common/CheckTextualResult.vue';
import VerificationContent from '../../IdentityVerificationReport/common/VerificationContent.vue';
import VerificationGrid from '../../IdentityVerificationReport/common/VerificationGrid.vue';

const props = defineProps<{
    accountDetails: BAVReturnData['account_details'];
    isBusinessReport: boolean;
}>();

function maskSSN(ssn: BavReportAccountHolder['ssn'], disableMask: boolean): BavReportAccountHolder['ssn'] {
    if (!ssn?.value) {
        return {
            match: MatchResultState.unknown,
            value: 'Not Provided',
            suppressMatch: true,
            source: SourceType.client,
        };
    }
    const last4 = ssn.value.slice(-4);
    return {
        match: ssn.match,
        value: disableMask ? ssn.value : `***-**-${last4}`,
        source: SourceType.client,
    };
}

const einComputed = computed(() => {
    // provide this value if we are in business report.
    // eins are sent through on the ssnMatch of the account holder array. If we have any 'Y' we have a match. otherwise return 'N' if any 'N' provided, or 'U' if all 'U'
    if (props.isBusinessReport) {
        const einMatch = props.accountDetails.account_holders.some(
            accountHolder => accountHolder.ssn.match === MatchResultState.match,
        )
            ? MatchResultState.match
            : props.accountDetails.account_holders.some(
                accountHolder => accountHolder.ssn.match === MatchResultState.noMatch,
            )
            ? MatchResultState.noMatch
            : MatchResultState.unknown;
        return {
            match: einMatch,
            value: props.accountDetails.business_information?.ein ?? '',
        }
    } else {
        return null;
    }
});
const businessNameComputed = computed(() => {
    // similarly to einMatch, we provide this value if we are in business report. We will key off the business_name_or_full_name_match for account holders. If we have any 'Y' we have a match. otherwise return 'N' if any 'N' provided, or 'U' if all 'U'
    if (props.isBusinessReport) {
        const businessNameMatch = props.accountDetails.account_holders.some(
            accountHolder => accountHolder.business_or_full_name?.match === MatchResultState.match,
        )
            ? MatchResultState.match
            : props.accountDetails.account_holders.some(
                accountHolder => accountHolder.business_or_full_name?.match === MatchResultState.noMatch,
            )
            ? MatchResultState.noMatch
            : MatchResultState.unknown;
        return {
            match: businessNameMatch,
            value: props.accountDetails.business_information?.business_name ?? '',
        }
    } else {
        return null;
    }
});
const accountHolderWithKey = computed(() => {
    return {
        ...props.accountDetails,
        account_holders: props.accountDetails.account_holders.map(accountHolder => {
            if (props.isBusinessReport) {
                accountHolder.ssn.value = props.accountDetails.business_information?.ein ?? '';
            }
            return {
                ...accountHolder,
                key: Math.floor(Math.random() * Math.pow(props.accountDetails.account_holders.length, 10)),
            };
        }),
    };
});
</script>
