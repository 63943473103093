<template>
    <InnerSideNavRoot>
        <template #title>Users</template>
        <InnerSideNavItem
            label="All users"
            url="/user/manage#all-users"
            :active="isRouteActive('#all-users')"
        />
    </InnerSideNavRoot>
</template>

<script lang="ts" setup>
import InnerSideNavItem from './InnerSideNavItem.vue';
import InnerSideNavRoot from './InnerSideNavRoot.vue';
import { useSettingsInnerSideNavHelper } from './settings-inner-side-nav.helper';

const { isRouteActive } = useSettingsInnerSideNavHelper({ defaultHash: '#all-users' });
</script>
