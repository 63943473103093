<template>
    <div class="flex flex-col h-full font-inter">
        <div class="w-full flex space-between px-8 mt-8">
            <div class="flex-1">
                <h2 class="font-bold">
                    {{ isBusinessReport ? 'Business account verification report' : 'Bank account verification report' }}
                </h2>
            </div>
        </div>
        <Divider v-if="showReportSections"/>
        <div class="px-5 flex flex-col gap-2">
            <div
                v-if="hasError"
                class="bg-red-100 p-4 rounded-lg mt-4"
            >
                <p class="text-red-600 font-bold">Error</p>
                <p class="text-red-600">Problem loading report. Please contact support.</p>
            </div>
            <div
                v-else-if="stillProcessing"
                class="bg-yellow-100 p-4 rounded-lg mt-4"
            >
                <p class="text-yellow-800 font-bold">Report Processing</p>
                <p class="text-yellow-800">The report is still processing. Please check back later.</p>
            </div>
            <template v-else-if="reportResult && showReportSections">
                <VerificationSection
                    title="File Information"
                    class="bg-neutral-50 px-2"
                    :with-divider="false"
                    :collapsable="false"
                >
                    <FileInformation :file-information="reportResult.file_information" />
                </VerificationSection>
                <VerificationSection
                    title="Banking Information"
                    class="bg-neutral-50 px-2"
                    :with-divider="false"
                    :collapsable="false"
                >
                    <BankingInformation :banking-information="reportResult.banking_information" :source-information="mergedSourceStatus" :additional-information="reportResult?.additional_information"/>
                </VerificationSection>
                <VerificationSection
                    title="Account Status"
                    class="bg-neutral-50 px-2 capitalize"
                    :with-divider="false"
                    :collapsable="false"
                >
                    <AccountStatus :account-status="reportResult.account_status" />
                </VerificationSection>
                <VerificationSection
                    title="Account Details"
                    class="bg-neutral-50 px-2"
                    :with-divider="false"
                    :collapsable="false"
                >
                    <AccountDetails
                        :account-details="reportResult.account_details"
                        :isBusinessReport
                    />
                </VerificationSection>
            </template>
        </div>
        <template v-if="showReportSections">
            <Divider />
            <div class="flex flex-row items-end justify-end gap-1 text-sm px-8">
                <a
                    :href="reportUrl"
                    target="_blank"
                    :download="`report-${currentReportId}.pdf`"
                    title="Download report"
                >
                    <Button class="w-fit bg-[#ddd] m-1 px-7 py-[15px] text-[#444] shadow-md border-neutral-100" unstyled>
                        <font-awesome-icon icon="fas fa-file-pdf" />
                    </Button>
                </a>

                <Button
                    @click="$emit('close')"
                    class="w-fit bg-[#ddd] m-1 px-7 py-[15px] text-[#444] shadow-md border-neutral-100" unstyled
                >
                    Close
                </Button>
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
import type { BAVReturnData } from '@/api/interfaces/bank-account-verification.api.d';
import { useBankAccountVerificationService } from '@/services/bank-account-verification.service';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import { computed, onBeforeUnmount, ref } from 'vue';
import VerificationSection from '../IdentityVerificationReport/common/VerificationSection.vue';
import AccountDetails from './reports/AccountDetails.vue';
import AccountStatus from './reports/AccountStatus.vue';
import BankingInformation from './reports/BankingInformation.vue';
import FileInformation from './reports/FileInformation.vue';

const bavService = useBankAccountVerificationService();

const reportUrl = ref<string>('');

const props = defineProps<{
    reportResult?: BAVReturnData;
    currentReportId?: string;
    hasError: boolean;
    stillProcessing: boolean;
}>();

const showReportSections = computed(() => !props.hasError && !props.stillProcessing);

if (props.currentReportId && showReportSections.value) {
    bavService.downloadReportById(props.currentReportId).then(url => (reportUrl.value = url));
}
const isBusinessReport = computed(() => !!props.reportResult?.account_details?.business_information?.ein);

const mergedSourceStatus = computed(() => {
    // look at the reportResult.banking_information.wire_routing.source and reportResult.banking_information.account_number.source
    // if either is not 'automatic', return 'manual'
    // otherwise return 'automatic'
    return props.reportResult?.banking_information?.wire_routing_number.source === 'automatic' &&
        props.reportResult?.banking_information?.account_number.source === 'automatic'
        ? 'automatic'
        : 'manual';
});


onBeforeUnmount(() => {
    URL.revokeObjectURL(reportUrl.value);
    reportUrl.value = '';
});
</script>
