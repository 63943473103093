<template>
    <h2 class="mt-0">Alert Banners</h2>
    <fieldset class="border mb-4 px-4 py-2 grid gap-4">
        <legend>Custom banner copy</legend>
        <div class="grid">
            <label for="title-msg">Title</label>
            <input class="rounded-sm" type="text" v-model="titleMsg" id="title-msg">

            <label class="pt-2" for="body-msg">Body</label>
            <textarea class="rounded-sm resize-y" v-model="bodyMsg" id="body-msg"></textarea>
        </div>
    </fieldset>

    <h3>Warning</h3>
    <DsAlertBanner class="mb-2" mode="warning" :title="titleMsg || 'Warning Banner'" />
    <DsAlertBanner mode="warning" :title="titleMsg || 'Warning Banner'">
        {{ bodyMsg || 'This text is optional and adds more context to banner title. Text wraps, never truncates.' }}
    </DsAlertBanner>

    <h3>Error</h3>
    <DsAlertBanner class="mb-2" mode="error" :title="titleMsg || 'Error Banner'" />
    <DsAlertBanner mode="error" :title="titleMsg || 'Error Banner'">
        {{ bodyMsg || 'This text is optional and adds more context to banner title. Text wraps, never truncates.' }}
    </DsAlertBanner>
</template>

<script setup lang="ts">
import DsAlertBanner from '@ds/DsAlertBanner.vue';
import { ref } from 'vue';

const titleMsg = ref('');
const bodyMsg = ref('');
</script>

<style lang="scss" scoped>
h2, h3 {
    @apply my-4;
}
</style>
