<template>
    <div
        class="bg-neutral-100 h-screen top-0 w-screen"
        :class="{ fixed: !isIntegrationRoute }"
    >
        <div
            class="fixed w-3/4 top-0 left-0 h-screen bg-neutral-50 animated-resize legacy-md:hidden"
            :class="{ '-translate-x-full': !sideNavBarVisible, 'translate-x-0': sideNavBarVisible }"
        >
            <div class="flex flex-col h-full">
                <router-view
                    name="sideNav"
                    class="pt-3"
                />
            </div>
        </div>
        <div
            class="top-0 left-0 w-full min-h-14 bg-neutral-100 animated-resize flex flex-row"
            :class="{
                'xs:translate-x-0': !sideNavBarVisible,
                'xs:translate-x-3/4': sideNavBarVisible,
            }"
        >
            <div
                @click="sideNavBarVisible = !sideNavBarVisible"
                class="legacy-md:hidden ml-4 mr-2 my-5 bg-neutral-100 cursor-pointer"
                :class="isLoginRoute || isIntegrationRoute ? 'hidden' : ''"
            >
                <img src="@/assets/svg/unified-menu.svg" />
            </div>
            <div class="flex-1 bg-neutral-100">
                <!-- <router-view name="mainNav" /> -->
                <slot name="top">
                    <router-view name="topNav" />
                </slot>
            </div>
        </div>
        <div
            class="flex flex-row top-14 w-full animated-resize gap-4"
            :class="{
                'xs:translate-x-3/4': sideNavBarVisible,
            }"
        >
            <SideNavPanel
                v-if="hasSideNav"
                v-model:expanded="showSideNav"
                class="legacy-md:flex xs:hidden bg-neutral-50 h-[calc(100vh-4rem)]"
                :class="{
                    'legacy-md:w-[16rem] legacy-md:min-w-[16rem]': showSideNav,
                    'legacy-md:w-16': !showSideNav,
                }"
            >
                <template v-slot:content="props">
                    <router-view
                        name="sideNav"
                        :expanded="props.isPanelExpanded"
                    />
                </template>
            </SideNavPanel>

            <!-- total width minus fixed header rem size - max-h viewport height minus TopNav -->
            <div
                class="flex-1 flex flex-col xs:w-full xs:left-0 bg-white content-shadow flex-grow overflow-x-auto"
                :class="{
                    'legacy-md:w-[calc(100%-14rem)]': showSideNav,
                    'legacy-md:flex-1': !showSideNav,
                    'max-h-[calc(100vh-72px)]': !isIntegrationRoute,
                }"
            >
                <div
                    class="w-full h-28 mb-1"
                    :class="{
                        'bg-neutral-50':
                            route.path.includes('/user/settings') ||
                            route.path.includes('/user/manage') ||
                            route.path.includes('/user/password'),
                        'title-nav-gradient':
                            !route.path.includes('/user/settings') &&
                            !route.path.includes('/user/manage') &&
                            !route.path.includes('/user/password'),
                        'pt-8': !route.path.includes('/user/password') || !route.path.includes('/user/payoffs'),
                        'pt-10': route.path.includes('/user/password') || route.path.includes('/user/payoffs'),
                    }"
                    v-if="hasTitleNav"
                >
                    <router-view
                        name="tabNav"
                        :hasEarlyDepositAccess="hasEarlyDepositAccess"
                        :hasPostCloseAccess="hasPostCloseAccess"
                        :userRole="userRole"
                    />
                </div>
                <div
                    class="flex flex-row gap-6"
                    :class="{
                        'legacy-md:pl-0': isLoginRoute,
                        'legacy-md:pl-8 overflow-y-scroll': !isLoginRoute,
                        'content-with-inner-side-nav': hasInnerSideNav,
                        'base-content-spacing': !hasInnerSideNav && hasBaseContentSpacing,
                    }"
                >
                    <router-view name="innerSideNav" />

                    <div
                        class="w-full flex-1"
                        :class="{
                            'min-h-[calc(100vh-200px)]': !isIntegrationRoute,
                        }"
                    >
                        <div
                            class="flex flex-col"
                            :class="{ 'min-h-[calc(100vh-164px)]': !isIntegrationRoute }"
                        >
                            <div :class="{ 'min-h-[calc(100vh-56px)]': !isIntegrationRoute }">
                                <div :class="{ 'min-h-[calc(100vh-40vh)]': !isLoginRoute, 'min-h-screen': isIntegrationRoute }">
                                    <div
                                        class="flex items-center justify-center align-top gap-6"
                                        :class="{
                                            'min-h-[calc(100vh-92px-128px)]': isLoginRoute && !isRoot,
                                            'w-100': !isLoginRoute,
                                        }"
                                    >
                                        <slot name="content">Loading content</slot>
                                    </div>
                                </div>

                                <div>
                                    <slot name="footer"></slot>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import SideNavPanel from '@/components/Navigation/side-nav/SideNavPanel.vue';
import type { SessionUserRole } from '@/interfaces/SessionUser';

import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const hasInnerSideNav = computed(() => !!route.matched?.find(match => !!match?.components?.innerSideNav));

defineProps<{
    hasEarlyDepositAccess: boolean;
    hasPostCloseAccess: boolean;
    userRole: SessionUserRole;
}>();

let hasSideNav: boolean = true;
let hasTitleNav: boolean = true;
let isRoot: boolean = false;
let isLoginRoute: boolean = false;
let isIntegrationRoute: boolean = false;

// used to hide the sideNav and tabNav on login pages
// the vue-router on page load was returning the wrong path ('/') on page load so we used window.location.pathname here
const noSidebarRoutes = ['login', 'user/mfa', 'password/reset', 'ssologin', 'client/home'];
const integrationRoutes = ['user/softpro', '/user/vendor/'];

if (window.location.pathname === '/') {
    hasSideNav = false;
    hasTitleNav = false;
    isRoot = true;
    isLoginRoute = true;
}

if (noSidebarRoutes.some(route => window.location.pathname.includes(route))) {
    hasSideNav = false;
    hasTitleNav = false;
    isLoginRoute = true;
}

if (integrationRoutes.some(route => window.location.pathname.includes(route))) {
    hasSideNav = false;
    hasTitleNav = false;
    isIntegrationRoute = true;
}

const showSideNav = ref<boolean>(true);
const sideNavBarVisible = ref<boolean>(false);
const hasBaseContentSpacing = (!isRoot && !isIntegrationRoute) || !isLoginRoute;
</script>

<style scoped lang="postcss">
.animated-resize {
    @apply transition-all ease-in-out duration-200;
}

.content-shadow {
    box-shadow:
        0px 8px 10px -6px rgba(16, 24, 40, 0.1),
        0px 20px 25px -5px rgba(16, 24, 40, 0.1);
}

.title-nav-gradient {
    background: linear-gradient(268deg, #f0f8ff 27.63%, rgba(240, 248, 255, 0.42) 61.12%);
}

.base-content-spacing {
    @apply pt-3;
}
.content-with-inner-side-nav {
    @apply pt-14;
}
</style>
