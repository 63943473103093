<template>
<div :class="{ 'dark-bg': useDarkBg }" class="use-custom-bg-color overflow-x-auto flex items-start gap-16 p-4">
    <div class="grid grid-cols-[repeat(4,_auto)] gap-4 items-center w-max">
        <em class="col-span-4 text-center justify-self-stretch">{{ variant }} {{ href ? 'link' : 'button' }} – lg</em>
        <em class="pr-8">Default</em>
        <DsButton :variant :destructive size="lg" :on-dark-bg="useDarkBg" :href :target @click="handleBtnClick('lg')">Button CTA</DsButton>
        <DsButton :variant :destructive size="lg" :on-dark-bg="useDarkBg" :href :target :leading @click="handleBtnClick('lg', 'leading')">
            Button CTA
        </DsButton>
        <DsButton :variant :destructive size="lg" :on-dark-bg="useDarkBg" :href :target :trailing @click="handleBtnClick('lg', 'trailing')">
            Button CTA
        </DsButton>

        <em>Disabled</em>
        <template v-if="!href">
            <DsButton :variant :destructive size="lg" :on-dark-bg="useDarkBg" :href :target disabled @click="handleBtnClick('lg')">Button CTA</DsButton>
            <DsButton :variant :destructive size="lg" :on-dark-bg="useDarkBg" :href :target disabled :leading @click="handleBtnClick('lg', 'leading')">
                Button CTA
            </DsButton>
            <DsButton :variant :destructive size="lg" :on-dark-bg="useDarkBg" :href :target disabled :trailing @click="handleBtnClick('lg', 'trailing')">
                Button CTA
            </DsButton>
        </template>
        <template v-else>
            <span class="justify-self-center">N/A</span>
            <span class="justify-self-center">N/A</span>
            <span class="justify-self-center">N/A</span>
        </template>
        <em>Loading</em>
        <DsButton :variant :destructive size="lg" :on-dark-bg="useDarkBg" :href :target loading @click="handleBtnClick('lg')">Button CTA</DsButton>
    </div>

    <div class="grid grid-cols-[repeat(3,_auto)] gap-4 justify-items-start items-center w-max">
        <em class="col-span-3 text-center justify-self-stretch">{{ variant }} {{ href ? 'link' : 'button' }} – md</em>
        <!-- <em>Default</em> -->
        <DsButton :variant :destructive size="md" :on-dark-bg="useDarkBg" :href :target @click="handleBtnClick('md')">Button CTA</DsButton>
        <DsButton :variant :destructive size="md" :on-dark-bg="useDarkBg" :href :target :leading @click="handleBtnClick('md', 'leading')">
            Button CTA
        </DsButton>
        <DsButton :variant :destructive size="md" :on-dark-bg="useDarkBg" :href :target :trailing @click="handleBtnClick('md', 'trailing')">
            Button CTA
        </DsButton>

        <!-- <em>Disabled</em> -->
        <template v-if="!href">
            <DsButton :variant :destructive size="md" :on-dark-bg="useDarkBg" :href :target disabled @click="handleBtnClick('md')">Button CTA</DsButton>
            <DsButton :variant :destructive size="md" :on-dark-bg="useDarkBg" :href :target disabled :leading @click="handleBtnClick('md', 'leading')">
                Button CTA
            </DsButton>
            <DsButton :variant :destructive size="md" :on-dark-bg="useDarkBg" :href :target disabled :trailing @click="handleBtnClick('md', 'trailing')">
                Button CTA
            </DsButton>
        </template>
        <template v-else>
            <span class="justify-self-center">N/A</span>
            <span class="justify-self-center">N/A</span>
            <span class="justify-self-center">N/A</span>
        </template>
        <!-- <em>Loading</em> -->
        <DsButton :variant :destructive size="md" :on-dark-bg="useDarkBg" :href :target loading @click="handleBtnClick('md')">Button CTA</DsButton>
    </div>

    <div class="grid grid-cols-[repeat(3,_auto)] gap-4 justify-items-start items-center w-max">
        <em class="col-span-3 text-center justify-self-stretch">{{ variant }} {{ href ? 'link' : 'button' }} – sm</em>
        <!-- <em>Default</em> -->
        <DsButton :variant :destructive size="sm" :on-dark-bg="useDarkBg" :href :target @click="handleBtnClick('sm')">Button CTA</DsButton>
        <DsButton :variant :destructive size="sm" :on-dark-bg="useDarkBg" :href :target :leading @click="handleBtnClick('sm', 'leading')">
            Button CTA
        </DsButton>
        <DsButton :variant :destructive size="sm" :on-dark-bg="useDarkBg" :href :target :trailing @click="handleBtnClick('sm', 'trailing')">
            Button CTA
        </DsButton>

        <!-- <em>Disabled</em> -->
        <template v-if="!href">
            <DsButton :variant :destructive size="sm" :on-dark-bg="useDarkBg" :href :target disabled @click="handleBtnClick('sm')">Button CTA</DsButton>
            <DsButton :variant :destructive size="sm" :on-dark-bg="useDarkBg" :href :target disabled :leading @click="handleBtnClick('sm', 'leading')">
                Button CTA
            </DsButton>
            <DsButton :variant :destructive size="sm" :on-dark-bg="useDarkBg" :href :target disabled :trailing @click="handleBtnClick('sm', 'trailing')">
                Button CTA
            </DsButton>
        </template>
        <template v-else>
            <span class="justify-self-center">N/A</span>
            <span class="justify-self-center">N/A</span>
            <span class="justify-self-center">N/A</span>
        </template>

        <!-- <em>Loading</em> -->
        <DsButton :variant :destructive size="sm" :on-dark-bg="useDarkBg" :href :target loading @click="handleBtnClick('sm')">Button CTA</DsButton>
    </div>
</div>
</template>

<script setup lang="ts">
import type { DsButtonProps } from '@ds/form/input/DsButton.vue';
import DsButton from '@ds/form/input/DsButton.vue';
import { computed } from 'vue';

interface DsButtonSampleProps {
    destructive?: DsButtonProps['destructive'],
    href?: DsButtonProps['href'],
    variant: DsButtonProps['variant'],
    leading: DsButtonProps['leading'],
    trailing: DsButtonProps['trailing'],
    useDarkBg: boolean,
}

const props = defineProps<DsButtonSampleProps>();
const target = computed(() => props.href ? '_blank' : undefined);

const handleBtnClick = (size: DsButtonProps['size'], showIcon?: 'leading' | 'trailing') => {
    const propsCopy = { ...props, size };

    if (showIcon !== 'leading') delete propsCopy.leading
    if (showIcon !== 'trailing') delete propsCopy.trailing

    console.log('DsButton clicked!', propsCopy);
}
</script>
