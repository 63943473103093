import { useClientApi, useCompanyApi } from '@/api/client/api-client';
import {
    type AccountType,
    type BankAccountCreationRequest,
    BankAccountCreationResponse,
    type BankAccountInfoResult,
    BankAccountNumberSource,
    type BankAccountReportResult,
    BankAccountVerificationRequest,
    BankAccountVerificationResult,
    BavFormAccountHolder,
    BAVFormData,
    BAVRequestAccountHolder,
    type BAVReturnDataWithStatusCode,
    NullSafeAccountTypes,
    type RoutingNumberVerificationResult
} from '@/api/interfaces/bank-account-verification.api.d';
import { removeNonDigits, splitName } from '@/shared/utils/strings';
import * as Yup from 'yup';

export function useBankAccountVerificationService() {
    const companyApi = useCompanyApi({ auth: 'jwt' });
    const clientApi = useClientApi({ auth: 'jwt' });

    const validGovIdNumberCheck =  (value: string) => {
        // Ensure GovID is exactly 9 digits (numeric only)
        const strippedValue = removeNonDigits(value);
        if (!/^\d{9}$/.test(strippedValue)) {
          return false;
        }
      
        // Prevent repetitive fake numbers like "111111111" or "999999999"
        if (/^(\d)\1+$/.test(strippedValue)) {
          return false;
        }
      
        return true; 
    }

    const validEINCheck = validGovIdNumberCheck; // EINs are the same as SSNs, but with a more relaxed rule set.

    // Using logic recommended by https://secure.ssa.gov/poms.nsf/lnx/0110201035
    const validSSNCheck = (value: string) => {
        if (!value) return false;
        const strippedValue = removeNonDigits(value);
        // additionally run the EIN check
        if (!validGovIdNumberCheck(strippedValue)) return false;
        const [_, area, group, serial] = strippedValue.match(/^(\d{3})(\d{2})(\d{4})$/) || [];
        if (['000', '666'].includes(area) || /^9\d{2}$/.test(area)) return false;
        if (group === '00') return false;
        if (serial === '0000') return false;
        return true;
    }
    
    async function validateRoutingNumber(routingNumber: string): Promise<RoutingNumberVerificationResult> {
        const validateRoutingNumberRoute = '/client/manualBAVValidateRoutingNumber';
        try {
            const { data, error } = await clientApi(validateRoutingNumberRoute)
                .post({ wire_routing_number: routingNumber })
                .json<RoutingNumberVerificationResult>();
            if (error.value || !data.value) {
                return Promise.reject(error.value || 'Unknown error occurred');
            }
            return data.value;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    const reshapeBankAccountDataFromResponse = (data: BankAccountInfoResult): BAVFormData => {
        // given a BankAccountInfoResult, we will try to reshape it into a BAVFormData object
        // do we have owners?
        if (!data.owners?.length) {
            throw new Error('No account holders found');
        }
        // assume we only have one valid owner. if we have more than one, we will use the first one.
        const owner = data.owners[0];
        // get our address from our first is_primary address
        const address = owner.addresses.find(address => address.is_primary);
        // create a single string from the address parts of `street`, `city`, `region`, and `postal_code`
        const client_address = address
            ? `${address.street}, ${address.city}, ${address.region}, ${address.postal_code}`
            : '';
        const account_holders: BavFormAccountHolder[] = owner.names.map(nameObj => {
            // SIGH, we get names from plaid as a single string. we will split it into first and last name.
            // we will assume that the first name is the first word, and the last name is the rest of the string.
            // this is a bad assumption, but it is the best we can do with the data we have.
            // the user will have to correct this if it is wrong.
            const { firstName, lastName } = splitName(nameObj.name);
            return {
                first_name: firstName,
                first_name_source: 'automatic',
                last_name: lastName,
                last_name_source: 'automatic',
            };
        });

        // create a BAVFormData object from the data we have.
        const formData: BAVFormData = {
            client_address,
            client_zip: address?.postal_code || '',
            client_zip_source: 'automatic',
            account_type: data.subtype,
            account_holders,
            account_number: data.ach_account,
            account_number_confirmation: data.ach_account,
            additional_information: data.notes || '',
            bank_name: data.institution,
            wire_routing_number: data.wire_routing,
            routing_number_source: 'automatic',
            account_number_source: 'automatic',
            valid_route_number: true, // we will assume that the route number is valid.
            bank_account_type: 'personal' as NullSafeAccountTypes, // all plaid accounts are treated as personal accounts.
        };
        return formData;
    }

    async function getBankInfo(clientFileId: string): Promise<BAVFormData> {
        const bankInfoRoute = `/client/bank-accounts/${clientFileId}`;
        try {
            const { data, error } = await clientApi(bankInfoRoute).get().json<BankAccountInfoResult>();
            if (error.value || !data.value) {
                return Promise.reject(error.value || 'Unknown error occurred while fetching bank account data');
            }
            const formData = reshapeBankAccountDataFromResponse(data.value);
            return formData;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async function verifyBankAccount(
        clientFileId: string,
        bavRequest: BankAccountVerificationRequest,
    ): Promise<BankAccountVerificationResult> {
        // This route is protected by a JWT token, so we need to get a JWT token to access it.
        const verificationRoute = `/api/bav/submitBav/${clientFileId}`;
        try {
            const { data, error } = await clientApi(verificationRoute).post(bavRequest).json<BankAccountVerificationResult>();
            if (error.value || !data.value || !data.value['bav-uuid']) {
                // if we have an error or are missing the bav-uuid, we will throw an error.
                throw error.value || new Error('Missing bav-uuid in response');
            }
            return data.value;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async function createManualBankAccount(
        request: BankAccountCreationRequest,
    ): Promise<BankAccountCreationResponse> {
        // This route-name is misleading, as it is actually used to create a manual bank account, needed for the BAV process.
        const createManualBankAccountRoute = '/client/manualBAV';
        try {
            const { data, error } = await clientApi(createManualBankAccountRoute)
                .post(request)
                .json<BankAccountCreationResponse>();
            if (error.value || !data.value) {
                throw new Error(error.value || 'Unknown error occurred while creating bank account');
            }
            return data.value;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    function buildBankAccountCreationRequestFromFormData(formData: BAVFormData, fileId: string): BankAccountCreationRequest {
        const { owner_name, owner_address } = getOwnerInfo(formData);
        return {
            owner_name,
            owner_address,
            bank_name: formData.bank_name!,
            account_number: removeNonDigits(formData.account_number),
            account_number_confirmation: removeNonDigits(formData.account_number_confirmation),
            routing_number: removeNonDigits(formData.wire_routing_number),
            file_id: fileId,
            notes: formData.additional_information,
            verification_service_down: false,
        };
    }

    const extractZipFromAddress = (address: string): string => {
        const zipMatches = address.match(/\b\d{5}\b/g);
        let computedZip = '';
        if (zipMatches) {
            computedZip = zipMatches[zipMatches.length - 1] || '';
        }
        return computedZip;
    }

    function buildBankAccountVerificationRequestFromFormData(formData: BAVFormData): BankAccountVerificationRequest {
        const triggeringClient = formData.account_holders.find(holder => holder.ssn);
        // find the first account holder with an SSN. that is our triggering client that we will compare the BAV results against
        const account_holders: BAVRequestAccountHolder[] = formData.account_holders.map(holder => {
            const strippedSSN = holder.ssn ? removeNonDigits(holder.ssn) : '';
            const accountHolder: BAVRequestAccountHolder = {
                first_name: holder.first_name,
                last_name: holder.last_name,
                first_name_source: holder.first_name_source,
                last_name_source: holder.last_name_source,
                triggering_client: triggeringClient === holder,
                ssn: strippedSSN,
                ssn_source: 'manual',
            };
            return accountHolder;
        });
        // if there is no SSN, we will use the first account holder as the triggering client
        if (!triggeringClient) {
            account_holders[0].triggering_client = true;
        }
        const computedZip = formData.client_zip || extractZipFromAddress(formData?.client_address ?? '') || '';
        const request: BankAccountVerificationRequest = {
            routing_number: removeNonDigits(formData.wire_routing_number),
            account_number: removeNonDigits(formData.account_number),
            routing_number_source: formData.routing_number_source,
            account_number_source: formData.account_number_source,
            additional_information: formData.additional_information,
            client_address: formData.client_address,
            client_zip_source: formData.client_zip_source || 'manual',
            client_zip: computedZip,
            account_holders,
        };
        if (formData.bank_account_type === 'business') {
            const strippedEIN = formData.ein ? removeNonDigits(formData.ein) : '';
            request.business_name = formData.business_name;
            request.ein = strippedEIN;
        }
        return request;
    }

    function getOwnerInfo(request: BAVFormData): { owner_name: string; owner_address: string } {
        let owner: BavFormAccountHolder;
        if (!request.account_holders) {
            throw new Error('No account holders found');
        }
        if (request.bank_account_type === 'business') {
            owner = request.account_holders[0];
        } else {
            owner = request.account_holders.find(holder => holder.ssn) as BavFormAccountHolder;
        }
        if (!owner) {
            throw new Error('No owner found with a valid SSN');
        }
        return {
            owner_name: `${owner.first_name} ${owner.last_name}`,
            owner_address: request.client_address,
        };
    }

    const buildManualBankAccountValidationSchema = (accountType: AccountType) => {
        let BankFormSchema = Yup.object().shape({
            client_address: Yup.string()
                .required('Account Holder address is required')
                .min(1, 'Please enter a valid address'),
            account_number: Yup.string()
                .min(4, 'Your account number must be at least 4 digits')
                .required('Account number is required'),
            account_number_confirmation: Yup.string()
                .min(4, 'Please confirm your account number')
                .required('Account confirmation is required')
                .oneOf([Yup.ref('account_number')], 'Account number confirmation does not match account number'),
            wire_routing_number: Yup.string()
                .required('Wire routing number is required')
                .length(9, 'Please enter a valid wire routing number'),
            additional_information: Yup.string().nullable(),
            bank_account_type: Yup.string(),
        });

        if (accountType === 'business') {
            BankFormSchema = BankFormSchema.shape({
                business_name: Yup.string().min(1, 'Please enter a valid business name').required('Business name is required'),
                ein: Yup.string()
                    .required('EIN number is required')
                    .matches(/^\d{2}-\d{7}$/, 'Please enter a valid EIN number')
                    .test('invalid-ein', 'Please enter a valid EIN number', value => {
                        return validEINCheck(value);
                    }),
                account_holders: Yup.array()
                    .of(
                        Yup.object().shape({
                            first_name: Yup.string().min(1, 'Please enter a first name'),
                            last_name: Yup.string().min(1, 'Please enter a last name'),
                        }),
                    )
                    .min(1, 'Please enter at least one account holder'),
            });
        } else {
            BankFormSchema = BankFormSchema.shape({
                account_holders: Yup.array()
                    .of(
                        Yup.object().shape({
                            first_name: Yup.string().min(1, 'Please enter a first name'),
                            last_name: Yup.string().min(1, 'Please enter a last name'),
                            ssn: Yup.string().nullable(),
                        }),
                    )
                    .test('at-least-one-ssn', 'At least one account holder must have a valid SSN', account_holders => {
                        const atLeastOne =
                            account_holders?.some(holder => holder.ssn && /^\d{3}-\d{2}-\d{4}$/.test(holder.ssn) && validSSNCheck(holder.ssn)) || false;
                        return atLeastOne;
                    })
                    .min(1, 'Please enter at least one account holder'),
            });
        }
        return BankFormSchema;
    };

    async function getReportById(bankAccountId: number): Promise<BAVReturnDataWithStatusCode> {
        try {
            // This is a JWT protected route, so we need to get a JWT token to access it.
            const { data, error, statusCode } = await companyApi(`/api/bav/report/${bankAccountId}`).get().json<BankAccountReportResult>();
            if (error.value || !data.value || statusCode.value && statusCode.value > 399) {
                window.Bugsnag?.notify('Error getting BAV report data', error.value);
                throw new Error('Error fetching bank account verification report');
            }
            // append the status code to the data object, for easier access.
            const returnValue: BAVReturnDataWithStatusCode = {
                ...data.value,
                statusCode: statusCode.value,
            };
            return returnValue;

        } catch (error) {
            return Promise.reject(error);
        }
    }

    async function downloadReportById(reportId: string): Promise<string> {
        try {
            const reportPdfRoute = `/api/bav/report/download/${reportId}`;
            // This is a JWT protected route, so we need to get a JWT token to access it.
            const { data, error, statusCode } = await companyApi(reportPdfRoute).get().blob();
            if (error.value || !data.value || statusCode.value !== 200) {
                window.Bugsnag?.notify('Error getting BAV report data', error.value);
                throw new Error('Error downloading bank account verification report');
            }
            const url = window.URL.createObjectURL(data.value!);
            return url;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    function formatBankingSource(source?: BankAccountNumberSource): string {
        switch (source) {
            case BankAccountNumberSource.manual:
                return 'User submitted';
            case BankAccountNumberSource.automatic:
                return 'Verified';
            case BankAccountNumberSource.client:
                return 'Client submitted';
            default:
                return '';
        }
    }

    return {
        validateRoutingNumber,
        verifyBankAccount,
        buildBankAccountCreationRequestFromFormData,
        buildBankAccountVerificationRequestFromFormData,
        buildManualBankAccountValidationSchema,
        formatBankingSource,
        getReportById,
        downloadReportById,
        createManualBankAccount,
        getBankInfo,
        validEINCheck,
        validSSNCheck,
        getOwnerInfo,
    };
}
