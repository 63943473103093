<template>
    <div class="flex flex-row gap-4 items-center mr-3 font-inter">
        <Button
            v-if="showAuthenticatedOnlyItems"
            :iconSize="24"
            class="p-0 legacy-md:pr-3 legacy-md:pl-5 pl-5 border-none focus:border-none bg-transparent hover:drop-shadow-none drop-shadow-none width-auto border-0 hover:shadow-none focus:shadow-none d-flex justify-center align-items-center font-inter"
            :menu="menuItems"
            @click="toggle"
            aria-haspopup="true"
            aria-controls="top_nav_menu"
        >
            <Avatar
                icon="pi pi-user"
                :label="currentUserService?.currentUser?.initials?.charAt(0) ?? 'U'"
                shape="circle"
            />
            <span
                class="hidden legacy-md:inline-block font-medium text-sm font-inter text-black capitalize px-1 truncate ... max-w-xxxs"
                >{{ currentUserService?.currentUser?.name?.split(' ')[0] }}</span>
            <span>
                <i
                    class="hidden legacy-md:block"
                    :class="
                        isToggled
                            ? [
                                  'pi',
                                  'pi-chevron-down',
                                  'transition',
                                  'rotate-180',
                                  'font-medium',
                                  'text-sm',
                                  'text-black',
                                  'px-1',
                              ]
                            : ['pi', 'pi-chevron-down', 'transition', 'font-medium', 'text-sm', 'text-black', 'px-1']
                    "
                />
            </span>
        </Button>
        <Menu
            ref="menu"
            id="top_nav_menu"
            :model="menuItems"
            popup
            :pt="{
                root: { class: 'bg-inherit bg-white border-none focus:bg-white p-0 font-inter right-3 md:right-5 left-auto' },
                menu: {
                    class: 'bg-white min-w-max left-auto font-inter text-xs font-semibold no-underline text-neutral-500',
                },
                content: { class: 'hover:bg-white border-none text-inherit hover:text-inherit' },
                menuitem: {
                    class: 'bg-white border-none focus:bg-white px-0 pb-0 pt-0',
                },
                action: 'w-full',
                button: { class: 'focus:bg-white active:bg-white' },
                submenu: 'max-w-max animate-fade-in',
            }"
            @blur="isToggled = false"
            @focus="isToggled = true"
        >
            <template #submenuheader="{ item }">
                <div
                    class="ml-1 p-3 font-inter text-sm font-semibold no-underline uppercase text-neutral-500 flex hover:primary-50"
                >
                    <img
                        class="mr-2 mb-3"
                        src="@/assets/svg/user-icon.svg"
                    />
                    <div class="flex flex-col text-inter line-clamp-1 max-w-xxxs text-neutral-600">
                        <span class="capitalize font-medium text-md overflow-hidden text-ellipsis">
                            {{ item.label }}
                        </span>
                        <p class="text-sm lowercase overflow-hidden text-ellipsis">
                            {{ currentUserService?.currentUser?.email }}
                        </p>
                    </div>
                </div>
            </template>
            <template #item="{ item }">
                <div class="border-t-2 border-color-neutral-300 p-1 bg-white mt-0">
                    <a
                        v-ripple
                        :href="item.url"
                        class="p-3 flex cursor-pointer hover:bg-primary-50 no-underline text-neutral-600 align-items-center text-sm"
                    >
                        <span :class="item.icon" />
                        <span class="ml-2 font-inter font-base">{{ item.label }}</span>
                    </a>
                </div>
            </template>
        </Menu>
    </div>
</template>

<script setup lang="ts">
import { useCurrentUserService } from '@/services/current-user.service';
import Avatar from 'primevue/avatar';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { computed, ref } from 'vue';
import { activeMenuItem } from './navigation-helper';

const props = defineProps<{
    isAuthenticated: boolean;
    allowChangePassword: boolean;
    hasAdminPrivileges: boolean;
    isLoginProcessRoute: boolean;
    clientPreCloseLink: string;
    clientPostCloseLink: string;
    payoffsLink: string;
    manageUsersLink: string;
    loginLink: string;
    settingsLink: string;
    updatePasswordLink: string;
}>();

const showAuthenticatedOnlyItems = computed(() => props.isAuthenticated && !props.isLoginProcessRoute);

const currentUserService = useCurrentUserService();

const logoutForm = ref<HTMLFormElement>();

const menu = ref();
const isToggled = ref(false);
const toggle = (event: Event) => {
    menu.value.toggle(event);
};

// on resize, close the menu
window.addEventListener('resize', () => {
    if (isToggled.value) {
        isToggled.value = false;
    }
});

const menuItems = ref([
    {
        class: activeMenuItem([props.settingsLink, props.updatePasswordLink]),
        label: currentUserService?.currentUser?.name,
        items: [
            {
                visible: props.allowChangePassword,
                label: 'Update password',
                icon: 'far fa-key',
                url: props.updatePasswordLink,
            },
            {
                label: 'Logout',
                icon: 'far fa-arrow-right-from-bracket',
                command: () => {
                    logoutForm.value?.submit();
                    currentUserService.logout();
                },
            },
        ],
    },
]);
</script>
