<template>
    <div class="w-full flex flex-col gap-1 font-normal font-inter">
        <label
            class="text-content-md font-medium text-content-secondary"
            v-if="label"
            :for="`${elementId}`"
        >
            <slot name="label">
                {{ label }}
                <small class="text-content-error">{{ required ? '*' : '' }}</small>
            </slot>
        </label>
        <div
            class="input-wrapper flex flex-row h-10 items-center text-content-primary text-content-lg leading-none relative p-3 rounded-[4px] w-full"
        >
            <div
                class="h-full flex flex-col justify-center px-2"
                v-if="$slots.leading"
            >
                <slot name="leading"></slot>
            </div>
            <input
                type="text"
                ref="inputElement"
                :id="`${elementId}`"
                v-model="model"
                :disabled
                :readonly
                :placeholder
                class="flex-1 p-0 bg-transparent h-5 m-0 border-none focus:ring-0"
            />
            <div
                class="h-full flex flex-col justify-center px-2"
                v-if="$slots.trailing"
            >
                <slot name="trailing"></slot>
            </div>
        </div>
        <div class="text-content-md font-normal text-content-secondary">
            <div
                v-if="hasError"
                class="text-content-error flex flex-row gap-2 items-center"
            >
                <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
                <slot name="error">
                    {{ errorMessage }}
                </slot>
            </div>
            <div v-else-if="!hasError && helperText">{{ helperText }}</div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';

const instance = getCurrentInstance();
const model = defineModel();

const props = withDefaults(
    defineProps<{
        id?: HTMLInputElement['id'];
        type: HTMLInputElement['type'];
        label?: string;
        placeholder?: HTMLInputElement['placeholder'];
        helperText?: string;
        disabled?: boolean;
        readonly?: boolean;
        required?: boolean;
        error?: string | boolean;
    }>(),
    {
        label: '',
        placeholder: '',
        helperText: '',
        error: false,
    },
);
const inputElement = ref<HTMLInputElement>();
const elementId = computed(() => props.id ?? instance?.uid ?? undefined);

const hasError = computed(() => !!props.error);
const errorMessage = computed(() => {
    if (typeof props.error === 'string') {
        return props.error;
    }
    return '';
});
function refreshInputValidity(hasError: boolean) {
    inputElement.value?.setCustomValidity(hasError ? errorMessage.value || 'Error' : '');
}

watch(hasError, refreshInputValidity);
onMounted(() => {
    refreshInputValidity(hasError.value);
});
</script>
<style scoped lang="scss">
/** We want to avoid having the state colors apply to the inner input element, which is visually neutral */
.input-wrapper {
    &:has(:active),
    &:focus-within {
        @apply border-color-primary-600  border-2;
    }

    &:focus-within {
        @apply shadow-color-primary-200 shadow-outline-field;
    }

    &:has(:disabled),
    :disabled {
        @apply text-content-disabled bg-color-gray-100 cursor-not-allowed;
    }

    &:has(:invalid) {
        @apply border-color-red-600  border-2;
    }

    @apply bg-primary hover:bg-secondary border border-gray-400 shadow-none;
}
</style>
