<script lang="ts" setup>
import { EditRoutingNumberSubmitPayload } from '@/employee-portal/pages/accounts/forms/form-payloads';
import { RoutingNumberValidation } from '@/interfaces/banking';
import { FlatWireDoc } from '@/interfaces/payment-settings/escrow-account';
import { useBankingService } from '@/services/banking.service';
import { debounce } from 'lodash';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { useField, useForm } from 'vee-validate';
import { onMounted, ref } from 'vue';

const props = defineProps<{ flatWireDoc: FlatWireDoc }>();
const emits = defineEmits<{
    (event: 'submit', payload: EditRoutingNumberSubmitPayload): void;
    (event: 'cancel'): void;
}>();
const bankingService = useBankingService();
const validatingBank = ref(false);
const routingNumberBank = ref<RoutingNumberValidation>({ isValid: true });

const { handleSubmit } = useForm();
const { value: routingNumber, errorMessage: routingNumberErrorMessage } = useField<string>('routingNumber', value => {
    if (!value) return 'Please enter a valid routing number';
    return true;
});

onMounted(() => {
    routingNumber.value = props.flatWireDoc.routing_number;
});

const onSubmit = handleSubmit(values => {
    emits('submit', {
        flatWireDoc: props.flatWireDoc,
        values: {
            routingNumber: values.routingNumber,
        },
    });
});
const onCancel = () => {
    emits('cancel');
};

const handleRoutingNumberChange = debounce(async () => {
    validatingBank.value = true;
    if (routingNumber.value) {
        try {
            const bankValidation = await bankingService.getRoutingNumberBank(routingNumber.value);
            routingNumberBank.value = bankValidation;
        } catch (error) {
            routingNumberBank.value.isValid = false;
        }
    }
    validatingBank.value = false;
}, 1000);
</script>

<template>
    <form @submit="onSubmit">
        <h3 class="text-center font-semibold mb-9">Enter the Routing Number</h3>
        <div class="flex flex-col gap-8 font-medium">
            <div class="flex flex-col gap-1">
                <label
                    for="routing-number"
                    class="text-sm font-normal"
                    >Routing Number:</label
                >
                <span class="p-input-icon-right">
                    <i
                        class="pi pi-spin pi-spinner float-right right-3 top-8 relative text-primary-800"
                        v-if="validatingBank"
                    />
                    <InputText
                        id="routing-number"
                        v-model="routingNumber"
                        @input="handleRoutingNumberChange"
                        type="text"
                        autocomplete="off"
                        @copy="e => e.preventDefault()"
                        @cut="e => e.preventDefault()"
                        @paste="e => e.preventDefault()"
                        class="input-blur w-full"
                    />
                </span>

                <small
                    class="text-red-500 text-xs"
                    v-show="!routingNumberBank.isValid || routingNumberErrorMessage"
                    >{{
                        routingNumberErrorMessage ||
                        `The routing number provided does not support wire transfers. 
                    Ask your financial institution for the correct wire routing number.`
                    }}
                </small>
            </div>

            <div class="flex flex-col gap-1 font-normal">
                <div>Bank Name:</div>
                <div v-if="!validatingBank && routingNumberBank.isValid">{{ routingNumberBank.bankName }}</div>
                <div
                    v-else
                    class="text-neutral-400"
                >
                    Enter a valid routing number above
                </div>
            </div>
            <div class="flex flex-col gap-1">
                <Button
                    label="Save Routing Number"
                    type="submit"
                    :disabled="validatingBank || !routingNumberBank.isValid"
                />
                <Button
                    outlined
                    label="Cancel"
                    type="button"
                    @click="onCancel"
                />
            </div>
        </div>
    </form>
</template>
