/**
 * Legacy context: Contains logic pertaining the Settings Inner Side Nav, which interacts with the older blade-based dom.
 * This helper is very specific to the settings page navigation mechanisms that hide/show elements
 * based on the route hash without vue-router managing that
 */

import { watch } from 'vue';
import { useRoute } from 'vue-router';

export function useSettingsInnerSideNavHelper({ defaultHash }: { defaultHash: string }) {
    const HIDE_CLASS = 'hidden';
    const route = useRoute();
    const sectionElements = document.querySelectorAll<HTMLElement>(`[data-route-hash]`);

    function refreshVisibility() {
        hideAllSections();
        if (!route.hash) {
            location.hash = defaultHash;
        }
        showSection(route.hash);
    }
    function hideAllSections() {
        sectionElements.forEach(el => {
            el.classList.add(HIDE_CLASS);
        });
    }

    /** Assumes the section will be tagged with an data-route-hash = hash */
    function showSection(hashId: string) {
        sectionElements.forEach(el => {
            if (el.dataset?.routeHash === hashId) {
                el.classList.remove(HIDE_CLASS);
            }
        });
    }

    function isRouteActive(routeHash: string) {
        return route.hash === routeHash;
    }

    watch(
        () => route.hash,
        () => {
            refreshVisibility();
        },
    );

    refreshVisibility();

    return {
        isRouteActive,
    };
}
