<template>
<fieldset class="border mb-4 px-4 py-2 grid gap-4">
    <legend>Controls</legend>
    <div>
        <label class="inline-flex items-center gap-2">
            <input type="checkbox" v-model="useDarkBg">
            Show dark background
        </label>
    </div>
    <div>
        Leading icons
        <div class="flex items-center gap-6 pt-1">
            <label class="inline-flex items-center gap-2">
                <input type="radio" v-model="leadingIcon" value="fa-chevron-left">
                <font-awesome-icon icon="fas fa-chevron-left" />
            </label>
            <label class="inline-flex items-center gap-2">
                <input type="radio" v-model="leadingIcon" value="fa-times">
                <font-awesome-icon icon="fas fa-times" />
            </label>
            <label class="inline-flex items-center gap-2">
                <input type="radio" v-model="leadingIcon" value="fa-check">
                <font-awesome-icon icon="fas fa-check" />
            </label>
            <label class="inline-flex items-center gap-2">
                <input type="radio" v-model="leadingIcon" :value="leadingIconCustom">
                <font-awesome-icon :icon="`fas ${leadingIconCustom}`" />
                <input type="text" class="rounded-md p-1" v-model="leadingIconCustom">
            </label>
        </div>
    </div>
    <div>
        Trailing icons
        <div class="flex items-center gap-6 pt-1">
            <label class="inline-flex items-center gap-2">
                <input type="radio" v-model="trailingIcon" value="fa-chevron-right">
                <font-awesome-icon icon="fas fa-chevron-right" />
            </label>
            <label class="inline-flex items-center gap-2">
                <input type="radio" v-model="trailingIcon" value="fa-times">
                <font-awesome-icon icon="fas fa-times" />
            </label>
            <label class="inline-flex items-center gap-2">
                <input type="radio" v-model="trailingIcon" value="fa-check">
                <font-awesome-icon icon="fas fa-check" />
            </label>
            <label class="inline-flex items-center gap-2">
                <input type="radio" v-model="trailingIcon" :value="trailingIconCustom">
                <font-awesome-icon :icon="`fas ${trailingIconCustom}`" />
                <input type="text" class="rounded-md p-1" v-model="trailingIconCustom">
            </label>
        </div>
    </div>
</fieldset>

<h2>Primary button</h2>
<DsButtonSampleSet variant="primary" :leading="leadingProp" :trailing="trailingProp" :use-dark-bg />
    
<h2>Secondary button</h2>
<DsButtonSampleSet variant="secondary" :leading="leadingProp" :trailing="trailingProp" :use-dark-bg />
    
<h2>Tertiary button</h2>
<DsButtonSampleSet variant="tertiary" :leading="leadingProp" :trailing="trailingProp" :use-dark-bg />
    
<h2>Primary destructive button</h2>
<DsButtonSampleSet v-if="!useDarkBg" destructive variant="primary" :leading="leadingProp" :trailing="trailingProp" :use-dark-bg />
<em v-else>No variant for dark background</em>
    
<h2>Secondary destructive button</h2>
<DsButtonSampleSet v-if="!useDarkBg" destructive variant="secondary" :leading="leadingProp" :trailing="trailingProp" :use-dark-bg />
<em v-else>No variant for dark background</em>
    
<h2>Tertiary destructive button</h2>
<DsButtonSampleSet v-if="!useDarkBg" destructive variant="tertiary" :leading="leadingProp" :trailing="trailingProp" :use-dark-bg />
<em v-else>No variant for dark background</em>

<h2>Primary link</h2>
<DsButtonSampleSet variant="primary" :leading="leadingProp" :trailing="trailingProp" :use-dark-bg href="https://www.google.com/" />
    
<h2>Secondary link</h2>
<DsButtonSampleSet variant="secondary" :leading="leadingProp" :trailing="trailingProp" :use-dark-bg href="https://www.google.com/" />
    
<h2>Tertiary link</h2>
<DsButtonSampleSet variant="tertiary" :leading="leadingProp" :trailing="trailingProp" :use-dark-bg href="https://www.google.com/" />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import DsButtonSampleSet from './DsButtonSampleSet.vue';

const leadingIcon = ref('fa-chevron-left');
const trailingIcon = ref('fa-chevron-right');

const leadingIconCustom = ref('fa-circle-question');
const trailingIconCustom = ref('fa-circle-question');

const leadingProp = computed(() => `fas ${leadingIcon.value}`);
const trailingProp = computed(() => `fas ${trailingIcon.value}`);

const useDarkBg = ref(false);
</script>

<style scoped lang="scss">
h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;

    &:first-child {
        margin-top: 0;
    }
}

h3 {
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.dark-bg {
    @apply bg-color-primary-900 text-white;
}
</style>
