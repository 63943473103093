<template>
    <div :class="{ 'add-box-shadow': addBoxShadow }">
        <h2>Icons</h2>

        <fieldset class="border grid gap-2 my-6 p-4">
            <legend>Controls</legend>
            <label>
                Font Awesome icon string
                <input class="border border-teal-500 shadow-[0_0_5px] shadow-teal-500 font-mono my-2 p-1 w-full" v-model="iconString">
            </label>

            <label>
                <input type="checkbox" v-model="addBoxShadow">
                Add box shadow
            </label>
        </fieldset>

        <h3 class="mt-4 mb-3 text-[1rem]">
            Small
            <small class="text-base">(sm / 1rem / 16px)</small>
        </h3>
        <DsIcon icon="fas fa-chevron-down" size="sm" />&nbsp;
        <DsIcon icon="far fa-circle-question" size="sm" />&nbsp;
        <DsIcon color="#ff0000ff" icon="fas fa-file-pdf" size="sm" />&nbsp;
        <DsIcon :icon="iconString" size="sm" class="border border-teal-500" />

        <h3 class="mt-4 mb-3 text-[1.5rem]">
            Medium
            <small class="text-base">(md / 1.5rem / 24px)</small>
        </h3>
        <DsIcon icon="fas fa-chevron-down" size="md" />&nbsp;
        <DsIcon icon="far fa-circle-question" size="md" />&nbsp;
        <DsIcon color="rgb(255, 0, 0)" icon="fas fa-file-pdf" size="md" />&nbsp;
        <DsIcon :icon="iconString" size="md" class="border border-teal-500" />

        <h3 class="mt-4 mb-3 text-[2rem]">
            Large
            <small class="text-base">(lg / 2rem / 32px)</small>
        </h3>
        <DsIcon icon="fas fa-chevron-down" size="lg" />&nbsp;
        <DsIcon icon="far fa-circle-question" size="lg" />&nbsp;
        <DsIcon color="#f00" icon="fas fa-file-pdf" size="lg" />&nbsp;
        <DsIcon :icon="iconString" size="lg" class="border border-teal-500" />

        <h3 class="mt-4 mb-3 text-[2.5rem]">
            Extra large
            <small class="text-base">(xl / 2.5rem / 40px)</small>
        </h3>
        <DsIcon icon="fas fa-chevron-down" size="xl" />&nbsp;
        <DsIcon icon="far fa-circle-question" size="xl" />&nbsp;
        <DsIcon color="red" icon="fas fa-file-pdf" size="xl" />&nbsp;
        <DsIcon :icon="iconString" size="xl" class="border border-teal-500" />
    </div>
</template>

<script setup lang="ts">
import DsIcon from '@ds/DsIcon.vue';
import { ref } from 'vue';

const iconString = ref('fa-solid fa-times');
const addBoxShadow = ref(true);
</script>

<style scoped lang="scss">
.add-box-shadow {
    :deep(.ds-icon-frame) {
        box-shadow: 0 0 5px gray;
        
        &:has(+ h3), &:last-child {
            box-shadow: 0 0 5px theme('boxShadowColor.teal.500');
        }
    }
}
</style>