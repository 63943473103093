<template>
    <VerificationContent :indentation-level="0">
        <VerificationGrid
            :indentation-level="0"
            :grid-cols="4"
            class="text-sm"
        >
            <CheckTextualResult
                label="Account standing:"
                :colorful-state="true"
                :value="accountStanding"
            />
            <CheckTextualResult
                label="Account Open Date:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: accountStatus.open_date || 'N/A',
                    })
                "
            />
            <CheckTextualResult
                label="Account last updated:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: accountStatus.last_update || 'Unknown',
                    })
                "
            />
        </VerificationGrid>
    </VerificationContent>
</template>

<script lang="ts" setup>
import { AccountStatusStanding, type BankAccountReportResult } from '@/api/interfaces/bank-account-verification.api.d';
import type { CheckResult } from '@/api/interfaces/identity-verification-report.api';
import { CheckResultState } from '@/api/interfaces/identity-verification-report.api';
import { makeCheckResult } from '@/components/Components/BankAccountVerification/report-check-result-mapping';
import { computed } from 'vue';
import CheckTextualResult from '../../IdentityVerificationReport/common/CheckTextualResult.vue';
import VerificationContent from '../../IdentityVerificationReport/common/VerificationContent.vue';
import VerificationGrid from '../../IdentityVerificationReport/common/VerificationGrid.vue';

const props = defineProps<{ accountStatus: BankAccountReportResult['account_status'] }>();

const accountStanding = computed<CheckResult>(() => {
    const standing = props.accountStatus?.standing;
    return makeCheckResult({
        state: standing === AccountStatusStanding.valid ? CheckResultState.Passed : CheckResultState.Failed,
        stateText: standing,
    });
});
</script>
