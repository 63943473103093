import { throttle } from 'lodash';
import { ref } from 'vue';

const resizeListener = throttle(() => {
    viewportHeight.value = window.innerHeight;
    viewportWidth.value = window.innerWidth;
}, 100);
window.addEventListener('resize', resizeListener, { passive: true });

export const viewportHeight = ref(window.innerHeight);
export const viewportWidth = ref(window.innerWidth);
