<template>
<component
    :is="href ? 'a' : 'button'"
    :href
    :target
    :type="href ? undefined : type"
    :class="[
        `ds-${href ? 'link' : 'button'}`,
        `ds-${variant}`,
        `ds-size-${btnSize}`,
        { 'ds-loading': loading, 'destructive': destructive, 'ds-on-dark-bg': onDarkBg },
    ]"
    :disabled="href ? undefined : (disabled || loading)"
>
    <slot name="leading">
        <DsIcon v-if="leading" :icon="leading" :size="iconSize" />
    </slot>

    <div class="relative">
        <slot>
            <!-- Button text will appear here -->
        </slot>

        <span v-if="loading" class="loading-spinner">
            <DsIcon icon="fa-duotone fa-solid fa-spinner-third" :size="iconSize" spin />
        </span>
    </div>

    <slot name="trailing">
        <DsIcon v-if="trailing" :icon="trailing" :size="iconSize" />
    </slot>
</component>
</template>

<script lang="ts" setup>
import { getLargestBreakpointMatch } from '@ds/design-system-breakpoints';
import DsIcon from '@ds/DsIcon.vue';
import type { FontAwesomeIconProps } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

type ButtonSize = 'lg' | 'md' | 'sm';

export interface DsButtonProps {
    destructive?: boolean,
    disabled?: boolean,
    href?: HTMLAnchorElement['href'] | null,
    leading?: FontAwesomeIconProps['icon'],
    loading?: boolean,
    onDarkBg?: boolean,
    rel?: HTMLAnchorElement['rel'],
    size: ButtonSize | Partial<Record<BreakpointSize, ButtonSize>>,
    target?: HTMLAnchorElement['target'],
    trailing?: FontAwesomeIconProps['icon'],
    type?: HTMLInputElement['type'],
    variant: 'primary' | 'secondary' | 'tertiary',
}

const props = withDefaults(
    defineProps<DsButtonProps>(), {
        type: 'button'
    }
);

const btnSize = computed<ButtonSize>(() => {
    if (typeof props.size === 'string') return props.size;
    return getLargestBreakpointMatch(props.size) ?? 'md';
});
const iconSize = computed(() => ({
    lg: 'md',
    md: 'md',
    sm: 'sm',
} as const satisfies Record<ButtonSize, string>)[btnSize.value]);
</script>

<style scoped lang="scss">
.ds-link {
    text-decoration: none;
}

.ds-link,
.ds-button {
    --fa-animation-duration: 1s;

    @apply border inline-flex items-center justify-center rounded-lg min-w-max;
    transition: background-color 200ms;

    &.ds-on-dark-bg {
        @apply text-white;
    }

    &:focus-visible {
        @apply border outline-none ring-4 ring-color-primary-200 ring-offset-color-primary-200;
    }
}

.ds-primary {
    @apply bg-primary-600 border-transparent text-white;

    &:hover {
        @apply bg-color-primary-700;
    }

    &:active {
        @apply bg-color-primary-800;
    }

    &:disabled {
        @apply bg-gray-300 cursor-not-allowed;

        &:not(.ds-loading) {
            @apply border-gray-300 text-content-disabled;
        }
    }

    &.ds-on-dark-bg {
        &:disabled {
            @apply bg-color-primary-700 border-transparent;

            &:not(.ds-loading) {
                @apply text-content-disabled;
            }
        }
    }

    &.destructive {
        @apply bg-color-red-600;

        &:hover {
            @apply bg-color-red-700;
        }

        &:active {
            @apply bg-color-red-800;
        }

        &:disabled {
            @apply bg-red-300;

            &:not(.ds-loading) {
                @apply border-red-300 text-red-500;
            }
        }

        &.ds-loading {
            @apply bg-color-red-800;
        }
    }

    &.ds-loading {
        @apply bg-color-primary-800 cursor-progress text-transparent;

        .loading-spinner {
            @apply text-white;
        }
    }
}

.ds-secondary, .ds-tertiary {
    @apply bg-transparent border-current text-color-primary-700;

    &:hover {
        @apply bg-color-gray-100;
    }

    &:active {
        @apply bg-color-gray-200;
    }

    &:disabled {
        @apply bg-white cursor-not-allowed;

        &:not(.ds-loading) {
            @apply text-content-disabled;
        }
    }

    &.ds-on-dark-bg {
        @apply bg-color-primary-900;

        &:hover {
            @apply bg-color-primary-800;
        }

        &:active {
            @apply bg-color-primary-700;
        }

        &:disabled {
            @apply bg-color-primary-900;

            &:not(.ds-loading) {
                @apply text-color-primary-700;
            }
        }

        &.ds-loading {
            @apply bg-color-primary-700;

            .loading-spinner {
                @apply text-white;
            }
        }
    }

    &.destructive {
        @apply border-transparent text-color-red-600;

        &:hover {
            @apply bg-color-red-50;
        }

        &:active {
            @apply bg-color-red-100;
        }

        &:disabled {
            &:not(.ds-loading) {
                @apply text-red-300;
            }
        }

        &.ds-loading {
            @apply bg-color-red-100;

            .loading-spinner {
                @apply text-color-red-700;
            }
        }
    }

    &.ds-loading {
        @apply bg-color-gray-200 cursor-progress text-transparent;

        .loading-spinner {
            @apply text-color-primary-700;
        }
    }
}

.ds-tertiary {
    @apply border-transparent;
}

.ds-size-lg {
    @apply h-12 px-6 text-base gap-2;
}

.ds-size-md {
    @apply h-10 px-4 text-base gap-2;
}

.ds-size-sm {
    @apply h-8 px-2 text-sm gap-1;
}

.loading-spinner {
    @apply absolute flex inset-0 items-center justify-center;
}
</style>
