<template>
    <div class="font-inter pt-5 pb-3 px-8">
        <h3 class="font-inter text-[16px] font-semibold mb-2">Early Deposit</h3>
        <DataTable
            :value="rowData.clients"
            :pt="subtableTheadStyles"
            :data-key="data => data.id"
        >
            <Column
                field="role"
                header="Sender"
                :pt="headerCellStyleOverrides"
                body-class="pr-4 border-x-transparent align-top"
            >
                <template #body="{ data: clientData }: ClientRowData">
                    <div class="font-semibold capitalize">{{ clientData.role }}</div>
                    <div v-if="clientData.first">{{ clientData.first }} {{ clientData.last }}</div>
                    <div>{{ clientData.email }}</div>
                </template>
            </Column>
            <Column
                header="Amount"
                :pt="headerCellStyleOverrides"
                :body-class
            >
                <template #body="{ data: clientData }: ClientRowData">
                    <div v-if="!clientData.pre_file_payments?.length">--</div>
                    <div v-for="payment in clientData.pre_file_payments">
                        {{ getPreFilePaymentAmountFormattedString(payment) }}
                    </div>
                </template>
            </Column>
            <Column
                field="created_at"
                header="Requested Date"
                :pt="headerCellStyleOverrides"
                :body-class
            >
                <template #body="{ data: clientData }: ClientRowData">
                    <div v-if="!clientData.pre_file_payments?.length">
                        {{ formatDate(rowData.created_at) }}
                    </div>
                    <div v-for="payment in clientData.pre_file_payments">
                        {{ formatDate(rowData.created_at) }}
                    </div>
                </template>
            </Column>
            <Column
                field="payment_sent_date"
                header="Sent Date"
                :pt="headerCellStyleOverrides"
                :body-class
            >
                <template #body="{ data: clientData }: ClientRowData">
                    <div v-if="!clientData.pre_file_payments?.length">--</div>
                    <div v-for="payment in clientData.pre_file_payments">
                        {{ payment.payment_created_at ?? '--' }}
                    </div>
                </template>
            </Column>
            <Column
                field="payment_status"
                header="Status"
                :pt="headerCellStyleOverrides"
                :body-class
            >
                <template #body="{ data: clientData }: ClientRowData">
                    <div v-if="!clientData.pre_file_payments?.length">Requested</div>
                    <div v-for="payment in clientData.pre_file_payments">
                        {{ payment.status }}
                    </div>
                </template>
            </Column>
            <Column
                header="Actions"
                header-class="text-transparent"
                :pt="headerCellStyleOverrides"
                :body-class
            >
                <template #body="{ data: clientData }: ClientRowData">
                    <div
                        v-for="payment in clientData.pre_file_payments"
                        class="text-center"
                    >
                        <!--                    <button-->
                        <!--                        v-if="!clientData.pre_file_payments[0]"-->
                        <!--                        class="px-1 options-icon shadow-none transition-opacity duration-150 text-danger"-->
                        <!--                        @click.stop="deleteEarlyDepositClient(rowData.id, clientData.id)"-->
                        <!--                        title="Remove Client"-->
                        <!--                    >-->
                        <!--                        <i class="fa-times fas" />-->
                        <!--                    </button>-->
                        <a
                            v-if="payment.status !== 'Requested' && payment.status !== 'Held' && payment.status !== 'Failed'"
                            class="px-1 options-icon text-primary"
                            :href="downloadEarlyDepositClientReceipt(payment.id)"
                            title="Download Receipt"
                        >
                            <i class="far fa-list-alt" />
                        </a>

                        <button
                            v-if="payment.status === 'Held' || payment.status === 'Failed'"
                            type="button"
                            :class="payment.id"
                            class="px-1 options-icon shadow-none transition-opacity duration-150 text-primary"
                            @click="toggle($event, payment, rowData.id, clientData.id)"
                            aria-haspopup="true"
                            aria-controls="overlay_menu"
                        >
                            <i class="fa-solid fa-ellipsis" />
                        </button>
                    </div>
                    <Menu
                        ref="menu"
                        id="overlay_menu"
                        :model="emdActions"
                        :popup="true"
                    >
                        <template #item="{ item, props }">
                            <a
                                v-ripple
                                class="flex items-center p-3 hover:bg-primary-50"
                                v-bind="props.action"
                                :href="item.url"
                                aria-hidden="false"
                            >
                                <span>{{ item.label }}</span>
                            </a>
                        </template>
                    </Menu>
                </template>
            </Column>
        </DataTable>

        <h3 class="font-inter text-[16px] font-semibold mt-6 mb-2">Notification Recipients</h3>
        <DataTable
            :value="rowData.notifying_users"
            :pt="subtableTheadStyles"
        >
            <Column
                field="email"
                header="Email"
                :pt="headerCellStyleOverrides"
                :body-class
            />
            <Column
                field="name"
                header="Name"
                :pt="headerCellStyleOverrides"
                :body-class
            />
        </DataTable>
    </div>
</template>

<script setup lang="ts">
import type { EarlyDeposit } from '@/api/interfaces/early-deposit-types';
import { downloadEarlyDepositClientReceipt, getEmdActionMenu } from '@/services/early-deposit.service';
import { formatDate } from '@/shared/utils/format-date';
import { formatMoney } from '@/shared/utils/format-number';
import Column from 'primevue/column';
import DataTable, { type DataTableProps } from 'primevue/datatable';
import Menu from 'primevue/menu';
import type { MenuItem } from 'primevue/menuitem';
import { ref } from 'vue';

// const { deleteEarlyDepositClient } = useEarlyDepositRowAction();

defineProps<{ rowData: EarlyDeposit }>();

type ClientRowData = { data: EarlyDeposit['clients'][number] };

const menu = ref();
const emdActions = ref<MenuItem[] | undefined>([
    {
        items: [
            {
                label: '',
                url: '',
                command: () => {},
            },
        ],
    },
]);

const toggle = (
    event: MouseEvent,
    paymentInfo: PreFilePayment,
    emdId: EarlyDeposit['id'],
    clientId: EarlyDeposit['clients'][0]['id'],
) => {
    emdActions.value = getEmdActionMenu(paymentInfo, emdId, clientId);
    menu.value.toggle(event);
};

const bodyClass = "border-x-transparent align-top";
const subtableTheadStyles: DataTableProps['pt'] = { thead: 'bg-[#F5F5F5] border-t border-t-neutral-600 font-inter' } as const;
const headerCellStyleOverrides: DataTableProps['pt'] = { headerCell: 'bg-transparent border-b-transparent py-3 text-sm' } as const;
const getPreFilePaymentAmountFormattedString = (preFilePayment?: PreFilePayment) => {
    return preFilePayment?.payment_base_amount ? formatMoney(preFilePayment.payment_base_amount / 100) : '--';
};
</script>

<style scoped lang="scss">
:deep(.font-inter) {
    button, input, optgroup, select, textarea, span, label {
        font-family: inherit;
    }
}
</style>
