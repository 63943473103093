<template>
    <div>
        <div v-if="!passcodeSent">
            <div>
                <h1 class="mt-14 mb-1.5">Send one-time code</h1>
                <p class="mb-4 text-slate-500 text-sm">
                    Your account requires multi-factor authentication. Select a delivery method for your code to complete log in.
                </p>
            </div>
            <div
                v-if="clientPhoneIsTextable"
                class="border border-slate-300 rounded p-4 mb-2.5"
                @click="selectPhoneType('sms')"
            >
                <input
                    type="radio"
                    value="sms"
                    v-model="phoneType"
                /><label class="ml-2">Text {{ phoneNumber }}</label>
            </div>
            <div
                class="border border-slate-300 rounded p-4 mb-2.5"
                @click="selectPhoneType('call')"
            >
                <input
                    type="radio"
                    value="call"
                    v-model="phoneType"
                /><label class="ml-2">Call {{ phoneNumber }}</label>
            </div>
            <a
                href="javascript:void(0)"
                class="text-slate-500 text-sm"
                @click="alreadyHasPasscode"
                >Already have a code?</a
            >
            <DsAlertBanner
                v-if="sendPasscodeError"
                mode="error"
                :title="sendPasscodeError"
                role="alert"
                class="mt-6"
            />
            <button
                type="button"
                class="block w-full text-center bg-brand-primary px-4 py-2 font-medium text-white text-uppercase hover:bg-brand-primary-hover-focus focus:outline-none focus:ring-2 focus:ring-brand-primary-hover-focus focus:ring-offset-2 rounded my-10"
                v-if="phoneType !== null"
                @click="sendPasscode"
            >
                {{ sendCodeButton }}
            </button>
        </div>
        <div v-if="passcodeSent">
            <div class="my-3">
                <div>
                    <h1 class="mt-14 mb-1.5">Enter one-time code</h1>
                    <p class="mb-4 text-slate-500 text-sm">
                        Your account requires multi-factor authentication. Enter the one-time use code you received through the
                        delivery method specified to complete log in.
                    </p>
                </div>
                <code-input
                    type="text"
                    @code="captureCode"
                    @keyup.enter="validatePasscode"
                    :error="!!validatePasscodeError"
                ></code-input>
                <DsAlertBanner
                    v-if="validatePasscodeError"
                    mode="error"
                    :title="validatePasscodeError"
                    role="alert"
                    class="my-6"
                />
                <a
                    href="javascript:void(0)"
                    class="text-slate-500 text-sm"
                    @click="resendCode"
                    >Resend Code</a
                >
            </div>
            <div class="mt-24">
                <button
                    type="button"
                    class="block w-full text-center bg-brand-primary px-4 py-2 font-medium text-white text-uppercase hover:bg-brand-primary-hover-focus focus:outline-none focus:ring-2 focus:ring-brand-primary-hover-focus focus:ring-offset-2 rounded my-10"
                    @click="validatePasscode"
                >
                    Log In
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import DsAlertBanner from '@ds/DsAlertBanner.vue';
import CodeInput from './CodeInput.vue';

    export default {
        name: 'TwoFAFormComponent',
        emits: ['passcodeValid', 'passcodeSent'],
        props: {
            clientPhoneType: {
                Type: String,
                required: false,
            },
            phoneNumber: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                passcode: '',
                phoneType: 'sms',
                passcodeSent: false,
                validatePasscodeError: '',
                sendPasscodeError: '',
            };
        },
        mounted() {
            this.phoneType = this.clientPhoneIsTextable ? 'sms' : 'call';
        },
        methods: {
            sendPasscode: function () {
                this.$http
                    .post('/client/login/sendPasscode', {
                        phoneType: this.phoneType,
                    })
                    .then(() => {
                        this.passcodeSent = true;
                        this.$emit('passcodeSent', this.phoneType);
                        this.sendPasscodeError = '';
                    })
                    .catch(err => {
                        const { data } = err.response;
                        const errorMessage = typeof data === 'string' ? data : data?.error_message;

                        this.sendPasscodeError = errorMessage || 'The phone number provided can not be reached. Please contact your title company to correct your contact info.';
                    });
            },
            alreadyHasPasscode: function () {
                this.passcodeSent = true;
            },
            validatePasscode: function () {
                this.$http
                    .post('/client/login/validatePasscode', {
                        passcode: this.passcode,
                    })
                    .then(response => {
                        if (response.data === 'passcode validated') {
                            this.$emit('passcodeValid', this.phoneType);
                        }
                    })
                    .catch(err => {
                        const passcodeErrors = err.response?.data?.errors?.passcode;

                        this.validatePasscodeError = Array.isArray(passcodeErrors)
                            ? passcodeErrors.join(', ')
                            : passcodeErrors || 'The code entered does not match the last code sent. Check the number and try again, or click resend code.';
                    });
            },
            captureCode: function (code) {
                this.passcode = code;
            },
            resendCode: function () {
                this.passcodeSent = false;
                this.validatePasscodeError = '';
            },
            selectPhoneType: function (value) {
                this.phoneType = value;
            },
        },
        computed: {
            sendCodeButton() {
                return this.phoneType === 'call' ? 'Call Me' : 'Send Code';
            },
            clientPhoneIsTextable() {
                return this.clientPhoneType === 'mobile' || this.clientPhoneType === 'voip';
            },
        },
        components: {
            'code-input': CodeInput,
            DsAlertBanner,
        },
    };
</script>
